import { Image, ImageViewer, Mask, SpinLoading, Swiper } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

import { Amount } from '@/components/Utils';
import { StorageKey, TabKey } from '@/configs/enum';
import BasicLayout from '@/layouts/basic';
import { resize } from '@/utils';

import ConstructionQualification from './components/ConstructionQualification';
import CustomizedConsult from './components/CustomizedConsult';
import SaasConsult from './components/SaasConsult';
import SystemCertification from './components/SystemCertification';
import { queryAdvertisingPosition, syncAccessShortLink } from './services';

import './style.scss';

export enum CategoryCode {
  ZHUANLIFUWU = 'ZHUANLIFUWU',
  TIXIRENZEN = 'TIXIRENZEN',
  XINYONGRENZHENG = 'XINYONGRENZHENG',
  SHANGBIAOFUWU = 'SHANGBIAOFUWU',
  JIANZHUZIZHI = 'JIANZHUZIZHI',
  RINEIWASAAS = 'RINEIWASAAS',
  FUWURENZHENG = 'FUWURENZHENG',
  BANQUANFUWU = 'BANQUANFUWU',
  RONGYIZIZHI = 'RONGYIZIZHI',
  HANGYEZHENGSHU = 'HANGYEZHENGSHU',
  GAOQIRENDING = 'GAOQIRENDING',
  REXIAO = 'REXIAO',
}

// const simpleList = [
//   CategoryCode.ZHUANLIFUWU,
//   CategoryCode.SHANGBIAOFUWU,
//   CategoryCode.JIANZHUZIZHI,
//   CategoryCode.RINEIWASAAS,
//   CategoryCode.FUWURENZHENG,
//   CategoryCode.BANQUANFUWU,
// ];

const consultList = [CategoryCode.JIANZHUZIZHI, CategoryCode.RINEIWASAAS, CategoryCode.FUWURENZHENG];

export enum ConsultType {
  SAAS,
  CONSTRUCTION,
  SYSTEM,
}

interface ImgInfo {
  imgUrl: string;
  deputyImgUrl: string;
}

interface Banner {
  targetUrl: string;
  imgInfo: ImgInfo;
}

// interface Category {
//   id: string;
//   advertisingName: string;
//   code: CategoryCode;
//   imgInfo: ImgInfo;
// }

interface GoodInfoVOList {
  goodName: string;
  turnover: string;
  originalPrice: string;
  price: string;
  goodImgUrl: string;
  goodId: string;
  imgUrl: string;
  promotionList: string[];
}

interface Advertising {
  id: string;
  advertisingName: string;
  code: CategoryCode;
  imgInfo: ImgInfo;
  goodInfoVOList: GoodInfoVOList[];
}

interface IConsult {
  visible: boolean;
  current?: string;
}

const ProductListItem = ({ item, isHot = false }: { item: GoodInfoVOList; isHot?: boolean }) => (
  <div
    className="productList-right-Box"
    onClick={() => {
      window.location.href = `/goods-module/goods?goods_id=${item.goodId}&referrer=/`;
    }}
  >
    <div className="productList-right-Box-imge">
      <img src={item.imgUrl || item.goodImgUrl} alt="" />
    </div>
    <div className="productList-right-text">
      <div className="productList-right-text-title">{item.goodName}</div>
      {item.promotionList?.length > 0 && (
        <div className="productList-right-text-frequency">
          {item.promotionList.map((promotion) => (
            <span key={promotion}>{promotion}</span>
          ))}
        </div>
      )}

      <div className="productList-right-text-price">
        <Amount value={Number(item.price)} />
      </div>
    </div>
    <Image className="positionImg" src={require('../../assets/images/add.png')} />
    {isHot && <span className="hot-icon"></span>}
  </div>
);

export const SimpleListItem = ({
  item,
  showThumb = true,
  isConsult = false,
  callConsultView,
}: {
  item: GoodInfoVOList;
  showThumb?: boolean;
  isConsult?: boolean;
  callConsultView?: () => void;
}) => (
  <div
    className="simple-list-item"
    onClick={() => {
      if (isConsult) {
        callConsultView?.();
        return;
      }
      window.location.href = `/goods-module/goods?goods_id=${item.goodId}&referrer=/`;
    }}
  >
    {showThumb && (
      <div className="item-image">
        <img src={item.imgUrl || item.goodImgUrl} alt="" />
      </div>
    )}

    <div className="productList-right-text">
      <div className="productList-right-text-title">{item.goodName}</div>
    </div>
    {isConsult ? (
      <Image className="positionImg" src={require('../../assets/images/consult.png')} />
    ) : (
      <Image className="positionImg" src={require('../../assets/images/add.png')} />
    )}
  </div>
);

export const ConsultListItem = ({ item }: { item: GoodInfoVOList }) => (
  <div className="simple-list-item">
    <div className="item-image">
      <img src={item.imgUrl || item.goodImgUrl} alt="" />
    </div>
    <div className="productList-right-text">
      <div className="productList-right-text-title">{item.goodName}</div>
      <Image className="positionImg" src={require('../../assets/images/add.png')} />
    </div>
  </div>
);

const transform: any = (data: Record<string, any>[]) => {
  return data.map((item) => {
    const obj = {
      label: item.name,
      value: JSON.stringify({ name: item.name, isLeaf: !item.children.length }),
      isLeaf: !item.children.length,
    };
    if (item.children?.length) {
      Object.assign(obj, { children: transform(item.children) });
    }
    return obj;
  });
};

// const findItem: any = (data: Record<string, any>[], ids: string) => {
//   const deepSearch = (list: Record<string, any>[], id: string) => {
//     const el = list.find((item) => item.value === id);
//     return el || deepSearch(el.children, id);
//   }
//   // data.forEach((item) => {})
//   // return data.map((item) => {
//   //   const obj = {
//   //     label: item.name,
//   //     value: item.name,
//   //     isLeaf: !item.children.length,
//   //   };
//   //   if (item.children?.length) {
//   //     Object.assign(obj, { children: transform(item.children) });
//   //   }
//   //   return obj;
//   // });
// };

const HomeContainer = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [categories, setCategories] = useState<Advertising[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Advertising>();
  const [advertising, setAdvertising] = useState<Advertising[]>([]);
  // const [cascaderOptions, setCascaderOptions] = useState<CascaderOption[]>([]);
  const [certModels, setCertModels] = useState<Advertising[]>([]);
  // const [cascaderValue, setCascaderValue] = useState<string[]>([]);
  // const [cascaderVisible, setCascaderVisible] = useState<boolean>(false);
  // const [fetchingIndustry, setFetchingIndustry] = useState<boolean>(false);
  const [productFetching, setProductFetching] = useState<boolean>(false);
  const [categoryFetching, setCategoryFetching] = useState<boolean>(false);
  const [preview, setPreview] = useState<{ visible: boolean; url?: string }>({ visible: false });
  const [maskVisible, setMaskVisible] = useState<boolean>(false);
  const [construction, setConstruction] = useState<IConsult>({
    visible: false,
  });
  const [systemCertification, setSystemCertification] = useState<IConsult>({
    visible: false,
  });
  const [saasConsult, setSaasConsult] = useState<IConsult>({
    visible: false,
  });
  const [customizedVisible, setCustomizedVisible] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const fetchBannerItems = async () => {
    const response = await queryAdvertisingPosition('WAPSYbanner');
    if (response?.code === 200 && response.data) {
      setBanners(response.data?.advertisingVO || []);
    }
  };

  const fetchCategories = async () => {
    setCategoryFetching(true);
    const response = await queryAdvertisingPosition('WAP_HOME_CATEGORY');
    setCategoryFetching(false);
    if (response?.code === 200 && response.data) {
      setCategories(response.data?.advertisingVO || []);
      setCurrentCategory(response.data?.advertisingVO?.[0]);
    }
  };

  const fetchGoods = async () => {
    setProductFetching(true);
    const response = await queryAdvertisingPosition('WAP_HOME_PRODUCTS');
    setProductFetching(false);
    if (response?.code === 200 && response.data) {
      setAdvertising(response.data.advertisingVO || []);
    }
  };

  const fetchCertModels = async () => {
    const response = await queryAdvertisingPosition('WAP_HOME_CERT_MODEL');
    if (response?.code === 200 && response.data) {
      setCertModels(response.data.advertisingVO || []);
    }
  };

  // const fetchIndustryTree = async () => {
  //   setFetchingIndustry(true);
  //   const response = await getIndustryTree();
  //   setFetchingIndustry(false);
  //   if (response?.code === 200) {
  //     const data = transform(response.data?.[0]?.children || []);
  //     setCascaderOptions(data);
  //     setCascaderVisible(true);
  //   }
  // };

  useEffect(() => {
    Promise.all([fetchBannerItems(), fetchCategories(), fetchGoods(), fetchCertModels()]);
  }, []);

  useEffect(() => {
    const syncShareStatus = async () => {
      const su = searchParams.get('su');
      if (su) {
        let uuid = localStorage.getItem(StorageKey.UUID);
        if (!uuid) {
          uuid = uuid4();
          localStorage.setItem(StorageKey.UUID, uuid);
        }
        await syncAccessShortLink({ uuid, su });
      }
    };
    syncShareStatus();
  }, [searchParams]);

  const currentTabGoods = React.useMemo(() => {
    if (currentCategory) {
      const advert = advertising.find((item) => item.code === currentCategory.code);
      if (advert) {
        return advert.goodInfoVOList;
      }
    }
    return [];
  }, [currentCategory, advertising]);

  return (
    <BasicLayout current={TabKey.HOME}>
      <div className="home-content">
        <div className="swiperBox">
          {banners.length > 0 && (
            <Swiper autoplay>
              {banners.map((item: Banner, index: number) => (
                <Swiper.Item key={index}>
                  <div
                    className="swiperClass"
                    onClick={() => {
                      window.location.href = `${item.targetUrl}?referrer=/`;
                    }}
                  >
                    <Image src={item.imgInfo?.imgUrl} />
                  </div>
                </Swiper.Item>
              ))}
            </Swiper>
          )}
        </div>
        <div className="productList">
          {productFetching || categoryFetching ? (
            <div
              style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
            >
              <SpinLoading color="#007AFF" />
            </div>
          ) : (
            <>
              {currentCategory && (
                <div className="productList-left">
                  {categories.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={`productList-left-imge-Box ${
                          currentCategory.code && currentCategory.code === item.code ? 'active ' : ''
                        }`}
                        onClick={() => {
                          // setindexAtvie(index);
                          setCurrentCategory(item);
                          // setCurrentTabGoods(goods[index].goodInfoVOList);
                        }}
                      >
                        {item.imgInfo && (
                          <Image
                            className={'productList-left-imge'}
                            src={
                              currentCategory.code === item.code
                                ? resize(item.imgInfo.deputyImgUrl, 25)
                                : resize(item.imgInfo.imgUrl, 25)
                            }
                            alt=""
                          />
                        )}
                        <span className="productList-left-imge-spanClass">{item.advertisingName}</span>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="productList-right">
                {/* {currentCategory && currentTabGoods?.length > 0 && (
                  <h5 className="category-title">{currentCategory.advertisingName}</h5>
                )} */}
                {currentTabGoods && currentTabGoods.length > 0 && currentCategory ? (
                  currentTabGoods.map((item) => {
                    if (consultList.includes(currentCategory.code)) {
                      return (
                        <SimpleListItem
                          isConsult
                          item={item}
                          key={item.goodId}
                          showThumb={currentCategory.code !== CategoryCode.JIANZHUZIZHI}
                          callConsultView={() => {
                            if (currentCategory.code === CategoryCode.JIANZHUZIZHI) {
                              setConstruction({
                                visible: true,
                                current: item.goodName,
                              });
                            }
                            if (currentCategory.code === CategoryCode.RINEIWASAAS) {
                              setSaasConsult({
                                visible: true,
                                current: item.goodName,
                              });
                            }
                            if (currentCategory.code === CategoryCode.FUWURENZHENG) {
                              setSystemCertification({
                                visible: true,
                                current: item.goodName,
                              });
                            }
                          }}
                        />
                      );
                    }
                    return (
                      <ProductListItem
                        item={item}
                        key={item.goodId}
                        // isHot={currentCategory.code === CategoryCode.REXIAO}
                      />
                    );
                  })
                ) : (
                  <div>
                    <Image className="nullData" src={require('../../assets/images/nullData.png')} />
                  </div>
                )}

                {currentTabGoods?.length > 0 && currentCategory?.code === CategoryCode.XINYONGRENZHENG && (
                  <div className="cert-model">
                    <h5 className="category-title">证书样式</h5>
                    <ul>
                      {certModels.map((item) => (
                        <li key={item.id}>
                          <Image
                            src={resize(item.imgInfo?.imgUrl, 120)}
                            alt=""
                            className="cert-item-img"
                            onClick={() => {
                              setPreview({ url: resize(item.imgInfo?.imgUrl, window.innerWidth), visible: true });
                            }}
                          />
                          <p>{item.advertisingName}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {/* {currentCategory?.code === CategoryCode.JIANZHUZIZHI && (
                  <Button
                    loading={fetchingIndustry}
                    color="primary"
                    className="btn-more"
                    onClick={() => {
                      fetchIndustryTree();
                    }}
                  >
                    更多建筑行业资质认证
                  </Button>
                )} */}
                {currentCategory?.code === CategoryCode.HANGYEZHENGSHU && (
                  <div
                    className="advert"
                    onClick={() => {
                      setCustomizedVisible(true);
                    }}
                  >
                    <img src={require('./images/advert.png')} alt="" />
                  </div>
                )}
                {/* <Cascader
                  title={<strong className="cascader-title">请选择资质名称</strong>}
                  options={cascaderOptions}
                  visible={cascaderVisible}
                  value={cascaderValue}
                  confirmText={<CloseOutline style={{ fontSize: 24, color: '#ccc', fontWeight: 'bold' }} />}
                  cancelText={null}
                  onSelect={(val, { items }) => {
                    setCascaderValue(val);
                    const selected = items?.[0];
                    if (selected.isLeaf) {
                      setConstruction({ visible: true, current: selected.name });
                      setCascaderVisible(false);
                      setCascaderValue([]);
                    }
                  }}
                  onConfirm={() => {
                    // setConstruction({
                    //   visible: true,
                    //   current: val[val.length - 1],
                    // });
                    setCascaderVisible(false);
                    setCascaderValue([]);
                  }}
                  onClose={() => {
                    setCascaderVisible(false);
                    setCascaderValue([]);
                  }}
                /> */}
                <ConstructionQualification
                  {...construction}
                  closeModal={() => {
                    setConstruction((state) => ({
                      ...state,
                      visible: false,
                      current: undefined,
                    }));
                  }}
                />
                <SaasConsult
                  {...saasConsult}
                  closeModal={() => {
                    setSaasConsult((state) => ({
                      ...state,
                      visible: false,
                      current: undefined,
                    }));
                  }}
                />
                <SystemCertification
                  {...systemCertification}
                  closeModal={() => {
                    setSystemCertification((state) => ({
                      ...state,
                      visible: false,
                      current: undefined,
                    }));
                  }}
                />
                <CustomizedConsult
                  visible={customizedVisible}
                  closeModal={() => {
                    setCustomizedVisible(false);
                  }}
                />
                <ImageViewer
                  image={preview.url}
                  visible={preview.visible}
                  onClose={() => {
                    setPreview({ visible: false, url: undefined });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <Mask visible={maskVisible}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100vw',
              height: '100vh',
            }}
          >
            <img src={require('./images/doublee.png')} alt="双11" style={{ width: '90vw', height: '90vw' }} />
            <img
              src={require('./images/close_btn.png')}
              alt="双11"
              style={{ width: 40, height: 40, marginTop: 20 }}
              onClick={() => {
                setMaskVisible(false);
              }}
            />
          </div>
        </Mask>
      </div>
    </BasicLayout>
  );
};

export default HomeContainer;
