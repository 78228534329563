import { Button, Checkbox, Form, Input, Popup, Toast } from 'antd-mobile';
import { LockOutline, PictureOutline, UserOutline } from 'antd-mobile-icons';
// import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { queryArticlesByPosition, queryUserInfo } from '@/services/common';
import { LoginParams } from '@/types';

import logoimg from './assets/images/logobig.png';
import { bindCurrentAccountPhone, getPhoneCaptcha } from './service';

import './Login.scss';
import { StorageKey } from '@/configs/enum';
import { useSearchParams } from 'react-router-dom';
import { isWechatEnvironment, wechatAuthentication } from '@/utils';

let timeChange: NodeJS.Timeout;

const BindPhone = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [currentPhone, setCurrentPhone] = useState<string>();
  const [currentCode, setCurrentCode] = useState<string>('');
  const [currentUUIDCode, setCurrentUUIDCode] = useState<string>('');
  const [captchaUrl, setCaptchaUrl] = useState<string>();
  const [time, setTime] = useState(60);
  const [privacy, setPrivacy] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState('获取验证码');
  const [agreementVisible, setAgreementVisible] = useState<boolean>(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const updateValidateCodeUrl = async () => {
    const uuidCode = uuidv4();
    const validateUrl = `/scbase/captchas/${uuidCode}/REGISTER_OR_LOGIN?r=${new Date().getTime()}`;
    setCurrentUUIDCode(uuidCode);
    setCaptchaUrl(validateUrl);
  };

  const getArticlesByPosition = async () => {
    const response = await queryArticlesByPosition('REGISTRATION_AGREEMENT');
    if (response) {
      setPrivacy(response);
    }
  };

  const startCountdown = () => {
    timeChange = setInterval(() => setTime((t) => --t), 1000);
    setBtnDisabled(true);
  };

  const getCurrentUser = async () => {
    const response = await queryUserInfo();
    if (response && !response.code) {
      localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(response));
    }
  };

  const handleSubmit = React.useCallback(
    async (values: LoginParams) => {
      const connect_uuid = localStorage.getItem(StorageKey.ULID);
      if (connect_uuid) {
        setSubmitting(true);
        const params = {
          ...values,
          scene: 'REGISTER_OR_LOGIN',
          client_type: 'WAP',
          uuid: currentUUIDCode,
          connect_uuid,
        };
        const response = await bindCurrentAccountPhone(params);
        setSubmitting(false);
        if (response) {
          if (response.code) {
            Toast.show({ content: response.message || '系统错误' });
            return;
          }
          // Cookies.set('access_token', response?.access_token, {
          //   domain: '.imipman.com',
          // });
          // Cookies.set('refresh_token', response?.refresh_token, {
          //   domain: '.imipman.com',
          // });
          // Toast.show({ content: '登录成功！', icon: 'success' });
          localStorage.setItem(StorageKey.ACCESS_TOKEN, response.access_token);
          localStorage.setItem(StorageKey.REFRESH_TOKEN, response.refresh_token);
          await getCurrentUser();
          Toast.show({ content: '绑定成功！', icon: 'success' });
          window.location.href = searchParams.get('redirect') || '/';
        }
      }
    },
    [currentUUIDCode, searchParams]
  );

  const handleGetPhoneCaptcha = React.useCallback(async () => {
    if (!currentPhone) {
      Toast.show({ content: '请输入手机号！' });
      return;
    }
    if (!currentCode) {
      Toast.show({ content: '请输入图形验证码！' });
      return;
    }
    if (!/^1\d{10}$/.test(currentPhone)) {
      Toast.show({ content: '手机号格式错误！' });
      return;
    }
    if (!checkedPrivacy) {
      Toast.show({ content: '请勾选并同意《用户服务协议》' });
      return;
    }
    const result = await getPhoneCaptcha({
      mobile: currentPhone,
      scene: 'REGISTER_OR_LOGIN',
      uuid: currentUUIDCode,
      captcha: currentCode,
    });
    if (result?.code) {
      Toast.show({ content: '验证码获取错误' });
      return;
    }
    startCountdown();
    Toast.show({ content: '验证码发送成功！' });
  }, [currentPhone, currentCode, checkedPrivacy, currentUUIDCode]);

  useEffect(() => {
    Promise.all([updateValidateCodeUrl(), getArticlesByPosition()]);
  }, []);

  useEffect(() => {
    return () => clearInterval(timeChange);
  }, []);

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent('获取验证码');
    }
  }, [time]);

  const updateCurrentUser = async () => {
    const result = await queryUserInfo();
    if (result) {
      if (!result.code) {
        localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(result));
      }
      // 如果已绑定手机号，重定向到订单列表
      if (result?.mobile) {
        window.location.href = '/order/list';
      }
      if (result.code === 703) {
        // TOKEN无效，重新授权登录
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
        localStorage.removeItem(StorageKey.REFRESH_TOKEN);
        if (isWechatEnvironment()) {
          wechatAuthentication(window.location.href);
        }
      }
    }
  };

  React.useEffect(() => {
    // 如果未登录，且环境是公众号
    const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
    if (!accessToken && isWechatEnvironment()) {
      wechatAuthentication(window.location.href);
    }
    if (accessToken) {
      updateCurrentUser();
    }
  }, []);

  return (
    <div className="login-container">
      <div className="logo" style={{ margin: '50px auto' }}>
        <img src={logoimg} alt="logo" style={{ width: 110, height: 110 }} />
      </div>
      <div className="login-card">
        <h3>绑定手机号</h3>
        <Form layout="horizontal" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="mobile"
            label={<UserOutline />}
            style={{ paddingLeft: 0 }}
            rules={[
              {
                validator: async (_, val) => {
                  if (!val) {
                    return Promise.reject(new Error('请输入手机号'));
                  }
                  if (val && !/^1\d{10}$/.test(val)) {
                    return Promise.reject(new Error('手机号格式有误'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="请输入手机号"
              onChange={(val) => {
                setCurrentPhone(val);
              }}
            />
          </Form.Item>
          <Form.Item
            name="captcha"
            label={<PictureOutline />}
            style={{ paddingLeft: 0 }}
            extra={
              <img
                src={captchaUrl}
                alt=""
                width={62}
                onClick={() => {
                  updateValidateCodeUrl();
                }}
              />
            }
            rules={[{ required: true, message: '请输入图形验证码' }]}
          >
            <Input
              placeholder="请输入图形验证码"
              maxLength={4}
              onChange={(val) => {
                setCurrentCode(val);
              }}
            />
          </Form.Item>
          <Form.Item
            name="sms_code"
            label={<LockOutline />}
            style={{ paddingLeft: 0 }}
            extra={
              <Button
                size="small"
                color="primary"
                fill="none"
                disabled={btnDisabled}
                onClick={() => handleGetPhoneCaptcha()}
              >
                {btnContent}
              </Button>
            }
            rules={[{ required: true, message: '请输入短信验证码' }]}
          >
            <Input placeholder="请输入短信验证码" maxLength={6} />
          </Form.Item>
          <div className="checkbox" style={{ padding: '15px 0', borderTop: '1px solid #eee' }}>
            <Checkbox
              onChange={(checked) => {
                setCheckedPrivacy(checked);
              }}
            >
              <span style={{ fontSize: 14 }}>同意</span>
              <Button
                color="primary"
                fill="none"
                style={{ fontSize: 14, padding: 0 }}
                onClick={() => {
                  setAgreementVisible(true);
                }}
              >
                《用户服务协议》
              </Button>
            </Checkbox>
          </div>
          <div className="operations" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              color="primary"
              type="submit"
              loading={submitting}
              style={{ width: '100%', height: 44, borderRadius: 22, fontWeight: 'bold' }}
            >
              提 交
            </Button>
          </div>
        </Form>
        <Popup
          visible={agreementVisible}
          onMaskClick={() => {
            setAgreementVisible(false);
          }}
          bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            maxHeight: '70vh',
          }}
        >
          <div style={{ padding: 10, overflowY: 'scroll', maxHeight: '70vh' }}>
            <div dangerouslySetInnerHTML={{ __html: privacy?.content }} />
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default BindPhone;
