import qs from 'qs';
import { ulid } from 'ulid';

import { ALLOW_PATHS, MP_APPID, MP_LOGIN_CALLBACK, WECHAT_AUTH_URL } from '@/configs/constant';
import { Environment, StorageKey } from '@/configs/enum';

/*
 * 参数说明：
 * price：要格式化的数字
 * unit: 货币符号
 * decimals：保留几位小数
 * thousands_sep：千分位符号
 * dec_point：小数点符号
 * */
export function transformMoney(
  price: string,
  unit?: string,
  decimals?: number,
  thousands_sep?: string,
  dec_point?: string
) {
  decimals = decimals || decimals === 0 ? decimals : 2; //这里默认设置保留两位小数，也可以注释这句采用传入的参数
  unit = unit != null ? unit : '￥'; // 默认货币为RMB
  price = (price + '').replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+price) ? 0 : +price,
    prec = decimals > 0 ? (!isFinite(+decimals) ? 0 : Math.abs(decimals)) : 0,
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point;
  var s = n.toString().split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  } else {
    if (prec !== 0) {
      s[1] = s[1].substring(0, prec); //小数点位数超出长度时截取前面的位数
    }
  }
  return unit + s.join(dec);
}

export const resize = (url: string, width = 100) => {
  return `${decodeURIComponent(url)}?x-oss-process=image/format,webp/resize,w_${width * 2}`;
};

export const fileDownload = (url: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  a.download = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const transform = (url: string, width = 100) => {
  return `${url}?x-oss-process=image/format,webp/resize,w_${width}`;
};

export const getFileThumbnail = (fileUrl: string) => {
  if (fileUrl) {
    const ext = fileUrl.split('.').pop();
    if (ext) {
      if (['png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
        // return transform(fileUrl, 120);
        return fileUrl;
      }
      if (['rar', 'zip'].includes(ext)) {
        return require('./images/zip.png');
      }
      if (['doc', 'docx'].includes(ext)) {
        return require('./images/word.png');
      }
      if (['xls', 'xlsx'].includes(ext)) {
        return require('./images/excel.png');
      }
      if (['pdf'].includes(ext)) {
        return require('./images/pdf.png');
      }
    }
  }
  return require('./images/file.png');
};

export const isImageFile = (url: string) => {
  if (url) {
    const ext = url.split('.').pop();
    return ext && ['png', 'jpg', 'jpeg', 'gif'].includes(ext);
  }
  return false;
};

export const getUAEnvironment = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isWeixin = ua.indexOf('micromessenger') !== -1;
  const isInApp = /(^|;\s)app\//.test(ua);
  if (isWeixin) {
    if ((window as any).__wxjs_environment === 'miniprogram') {
      return Environment.MINIAPP;
    } else {
      return Environment.MP;
    }
  } else {
    if (!isInApp) {
      return Environment.BROWSER;
    } else {
      return Environment.APP;
    }
  }
};

export const isWechatEnvironment = () => {
  return getUAEnvironment() === Environment.MP;
};

export const wechatAuthentication = (redirect = '/') => {
  // const authorizing = localStorage.getItem(StorageKey.AUTHORIZING);
  if (!ALLOW_PATHS.includes(window.location.pathname)) {
    let state = localStorage.getItem(StorageKey.ULID);
    // localStorage.setItem(StorageKey.AUTHORIZING, 'PROGRESSING');
    if (!state) {
      state = ulid();
      localStorage.setItem(StorageKey.ULID, state);
    }
    const params = {
      appid: MP_APPID,
      redirect_uri: MP_LOGIN_CALLBACK + '?redirect=' + redirect,
      response_type: 'code',
      scope: 'snsapi_base',
      state,
    };
    const url = `${WECHAT_AUTH_URL}?${qs.stringify(params)}#wechat_redirect`;
    window.location.href = url;
  }
};

export const getVariantName = (arr: Record<string, any>[]) => {
  return arr.map((item) => item.spec_value).join(',');
};
