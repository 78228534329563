import React from 'react';

export const THEME_COLOR = '#FF5E24';

export const Amount = ({
  value,
  size = 20,
  color = THEME_COLOR,
  linethrough = false,
}: {
  value: number;
  size?: number;
  color?: string;
  linethrough?: boolean;
}) => {
  const dig = React.useMemo(() => {
    const integer = Math.trunc(value);
    const val = Math.round((value - integer) * 100);
    return val || '00';
  }, [value]);

  const amount = React.useMemo(() => {
    const integer = Math.trunc(value);
    const reg = /(-?\d+)(\d{3})/;
    const org = integer.toString();
    const sep = ',';
    return reg.test(org) ? org.replace(reg, '$1' + sep + '$2') : org;
  }, [value]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          fontSize: `${size * 0.7 || 24}px`,
          color: color || THEME_COLOR,
          lineHeight: `${size * 0.8 || 24}px`,
          textDecoration: linethrough ? 'line-through' : 'none',
        }}
      >
        ￥
      </div>
      <div
        style={{
          fontSize: `${size || 24}px`,
          color: color || THEME_COLOR,
          lineHeight: `${size || 24}px`,
          textDecoration: linethrough ? 'line-through' : 'none',
        }}
      >
        {amount}
      </div>
      <div
        style={{
          fontSize: `${size * 0.7 || 24}px`,
          color: color || THEME_COLOR,
          lineHeight: `${size * 0.8 || 24}px`,
          textDecoration: linethrough ? 'line-through' : 'none',
        }}
      >
        .{dig}
      </div>
    </div>
  );
};

export const PlainAmount = ({
  value,
  size = 20,
  color = THEME_COLOR,
  linethrough = false,
}: {
  value: number;
  size?: number;
  color?: string;
  linethrough?: boolean;
}) => {
  const dig = React.useMemo(() => {
    const integer = Math.trunc(value);
    const val = Math.round((value - integer) * 100);
    return val || '00';
  }, [value]);

  const amount = React.useMemo(() => {
    const integer = Math.trunc(value);
    const reg = /(-?\d+)(\d{3})/;
    const org = integer.toString();
    const sep = ',';
    return reg.test(org) ? org.replace(reg, '$1' + sep + '$2') : org;
  }, [value]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          fontSize: `${size || 24}px`,
          color: color || THEME_COLOR,
          lineHeight: `${size || 24}px`,
          fontWeight: 'bold',
          textDecoration: linethrough ? 'line-through' : 'none',
        }}
      >
        ￥{amount}.{dig}
      </div>
    </div>
  );
};
