
import { RightOutline } from 'antd-mobile-icons';
import React, { ReactNode } from 'react';

import './index.css';

interface ComponentProp {
  children?: ReactNode;
  title?: string;
  footer?: string;
  naviable?: boolean;
  headerNav?: boolean;
  headerNavLabel?: string;
  handleNavPress?: () => void;
  borderPrimary?: boolean;
  radius?: boolean;
  headerBorder?: boolean;
  backgroundColor?: string;
  size?: string;
}

const Section: React.FC<ComponentProp> = ({
  children,
  title,
  footer,
  headerNav = false,
  headerNavLabel,
  handleNavPress,
  borderPrimary = false,
  radius = true,
  headerBorder = false,
  backgroundColor,
}) => {
  return (
    <div
      className={`section-wrap ${borderPrimary ? 'border-primary' : ''}`}
      style={{ borderRadius: radius ? 8 : 0, backgroundColor: backgroundColor || '#fff' }}
    >
      {(title || headerNav || headerNavLabel) && (
        <div className={`header ${headerBorder ? 'border' : ''}`} onClick={() => handleNavPress && handleNavPress()}>
          {title && <span className='header-title'>{title}</span>}
          {(headerNavLabel || headerNav) && (
            <div className='header-nav'>
              {headerNavLabel && <span className='header-nav-label'>{headerNavLabel}</span>}
              {headerNav && <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#999' }} />}
            </div>
          )}
        </div>
      )}
      {children && (
        <div className='section-content'>
          <div style={{ width: '100%' }}>{children}</div>
        </div>
      )}
      {footer && (
        <div className='footer'>
          <span className='footer-text'>{footer}</span>
        </div>
      )}
    </div>
  );
};


export default React.memo(Section);
