import { Form, Input, Modal, Toast } from 'antd-mobile';
import React, { useState } from 'react';

import { createConsult } from '../services';

const CustomizedConsult: React.FC<{
  visible: boolean;
  closeModal: () => void;
}> = ({ visible, closeModal }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    const vals = await form.validateFields();
    if (vals && !submitting) {
      const baseParams = {
        type: 11,
        source: 'WAP-首页',
        keyword: `企业名称:${vals.companyName},所属行业:${vals.industry_name},联系人:${vals.uname},联系方式:${vals.phone}`,
      };
      setSubmitting(true);
      const result = await createConsult({
        ...baseParams,
        ...vals,
      });
      setSubmitting(false);
      if (result?.id) {
        Toast.show({ icon: 'success', content: result.message || '提交成功' });
        closeModal();
      } else {
        Toast.show({ icon: 'fail', content: result.message || '系统繁忙，请稍后尝试' });
      }
    }
  };
  return (
    <Modal
      visible={visible}
      title="个性化需求定制"
      closeOnMaskClick
      showCloseButton
      content={
        <Form form={form} layout="horizontal" style={{ margin: '20px 0 10px' }}>
          <Form.Item
            name="companyName"
            label={<span style={{ fontSize: 14 }}>企业名称</span>}
            rules={[{ required: true, message: '请输入企业名称' }]}
          >
            <Input placeholder="请输入您的企业名称" style={{ fontSize: 14 }} />
          </Form.Item>
          <Form.Item
            name="industry_name"
            label={<span style={{ fontSize: 14 }}>所属行业</span>}
            rules={[{ required: true, message: '请输入所属行业' }]}
          >
            <Input placeholder="请输入您的行业，如：纺织" style={{ fontSize: 14 }} />
          </Form.Item>
          <Form.Item
            name="uname"
            label={<span style={{ fontSize: 14 }}>联系人</span>}
            rules={[{ required: true, message: '请输入联系人' }]}
          >
            <Input placeholder="我们该如何称呼您" style={{ fontSize: 14 }} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={<span style={{ fontSize: 14 }}>联系电话</span>}
            rules={[{ required: true, message: '请输入联系电话' }]}
          >
            <Input placeholder="用于接收查询结果" style={{ fontSize: 14 }} />
          </Form.Item>
        </Form>
      }
      onClose={() => {
        closeModal();
      }}
      actions={[
        {
          key: 'confirm',
          text: '立即咨询',
          primary: true,
          className: 'border-radius',
          disabled: submitting,
          onClick: () => {
            handleSubmit();
          },
        },
      ]}
    />
  );
};

export default CustomizedConsult;
