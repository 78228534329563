import { Image, Modal, TabBar } from 'antd-mobile';
import { SearchOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';

import { TabKey } from '@/configs/enum';

import activityActiveIcon from '../../assets/activity-active.png';
import activityIcon from '../../assets/activity.png';
import homeActiveIcon from '../../assets/home-active.png';
import homeIcon from '../../assets/home.png';
import code0 from '../../assets/images/code0.jpg';
import code1 from '../../assets/images/code1.jpg';
import code2 from '../../assets/images/code2.jpg';
import code3 from '../../assets/images/code3.jpg';
import code4 from '../../assets/images/code4.jpg';
import personActiveIcon from '../../assets/member-active.png';
import personIcon from '../../assets/member.png';
import orderActiveIcon from '../../assets/order-active.png';
import orderIcon from '../../assets/order.png';

import './style.scss';

const BUSINESS_MEMBER_ACCOUNT = 5;

// const routerMap: Record<string, any> = {
//   '/': 'home',
//   '/activity': 'activity',
//   '/order/list': 'order',
//   '/member/center': 'member',
// };

export interface LayoutProps {
  children: React.ReactNode;
  hideNavibar?: boolean;
  current?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, current = TabKey.HOME, hideNavibar = false }) => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const [currentTabKey, setCurrentTabKey] = useState<string>('home')
  const tabs = [
    {
      key: TabKey.HOME,
      title: '首页',
      // icon: <AppOutline />,
      icon: (active: boolean) =>
        active ? (
          <img src={homeActiveIcon} width={24} height={24} alt="" />
        ) : (
          <img src={homeIcon} width={24} height={24} alt="" />
        ),
      // badge: Badge.dot,
      router: '/',
    },
    {
      key: TabKey.ACTIVITY,
      title: '活动',
      // icon: <UnorderedListOutline />,
      icon: (active: boolean) =>
        active ? (
          <img src={activityActiveIcon} width={24} height={24} alt="" />
        ) : (
          <img src={activityIcon} width={24} height={24} alt="" />
        ),
      // badge: "5",
      router: '/activity',
    },
    {
      key: TabKey.ORDER,
      title: '订单',
      icon: (active: boolean) =>
        active ? (
          <img src={orderActiveIcon} width={24} height={24} alt="" />
        ) : (
          <img src={orderIcon} width={24} height={24} alt="" />
        ),
      // badge: "99+",
      router: '/order/list',
    },
    {
      key: TabKey.MEMBER,
      title: '我的',
      // icon: <UserOutline />,
      icon: (active: boolean) =>
        active ? (
          <img src={personActiveIcon} width={24} height={24} alt="" />
        ) : (
          <img src={personIcon} width={24} height={24} alt="" />
        ),
      router: '/member/center',
    },
  ];

  const onTabBarChange = (key: string) => {
    // setCurrentTabKey(key);
    const tabItem = tabs.find((tab) => tab.key === key);
    if ([TabKey.HOME, TabKey.ACTIVITY].includes(key as TabKey) && tabItem) {
      navigate(tabItem.router, { replace: true });
      return;
    }
    if (tabItem) {
      window.location.href = tabItem.router;
    }
  };

  return (
    <div className="container">
      <div className="uwrap">
        {!hideNavibar && (
          <div className="navTab">
            <div className="navTab-left-imge">
              <img src={require('../../assets/images/logo.png')} alt="" />
            </div>
            <div
              className="navTab-conter-input"
              onClick={() => {
                window.location.href = '/pages/search/search';
              }}
            >
              <div className="navTab-conter-input-left">
                <SearchOutline fontSize={18} color="#666" />
                <div className="placeholder">ISO认证</div>
              </div>
              <div className="navTab-conter-input-right">搜索</div>
            </div>
            <div className="ome-navTab-right-icon">
              <Image
                className="imge"
                src={require('../../assets/images/navCode.png')}
                alt=""
                onClick={() => {
                  Modal.show({
                    content: (
                      <div style={{ padding: 20, backgroundColor: '#fff' }}>
                        <Image
                          className="imge"
                          src={[code0, code1, code2, code3, code4][Math.floor(Math.random() * BUSINESS_MEMBER_ACCOUNT)]}
                          alt=""
                        />
                      </div>
                    ),
                    closeOnMaskClick: true,
                  });
                }}
              />
              <a href="tel:400-016-3160">
                <Image className="imge" src={require('../../assets/images/navService.png')} alt="" />
              </a>
            </div>
          </div>
        )}
        {children}
      </div>
      <div className="footer">
        <TabBar safeArea onChange={onTabBarChange} activeKey={current}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
};

export default Layout;
