import qs from 'qs';
import request, { advertapi, buyerapi } from '@/utils/request';
import { BindPhoneParams, LoginParams, RegisterParams } from '@/types';

export const getPhoneCaptcha = (payload: { uuid: string; captcha: string; scene: string; mobile: string }) => {
  const { mobile, ...params } = payload;
  return buyerapi.post(`passport/smscode/${mobile}?${qs.stringify(params)}`, undefined, { handleCatchOriginal: true });
};

export const loginWithCaptcha = (params: LoginParams) => {
  return buyerapi.post(`passport/registerLogin?${qs.stringify(params)}`, undefined, {
    checkAuth: false,
    handleCatch: true,
    headers: { uuid: params.uuid },
  });
};

// export const loginWithCaptcha = (params: LoginParams) => {
//   return request.post(`/scbuyer/passport/login/${params.mobile}?${qs.stringify(params)}`, undefined, {
//     checkAuth: false,
//     handleCatch: true,
//     headers: { uuid: params.uuid },
//   });
// };

export const getValidateCodeUrl = (params: { uuid: string; type: string }) => {
  const { uuid, type } = params;
  return advertapi.get(`captchas/${uuid}/${type}?r=${new Date().getTime()}`, undefined, { headers: { uuid } });
};

export const loginWithAccount = (params: LoginParams) => {
  return buyerapi.post(`passport/login?${qs.stringify(params)}`, undefined, {
    checkAuth: true,
    handleCatch: false,
  });
};

export const getArticleByPosition = (position: string) => {
  return request.get(`pages/${position}/articles`, undefined, { handleCatch: true });
};

export const getRegisterPhoneCaptcha = (mobile: string, params: { uuid: string; captcha: string; scene: string }) => {
  return buyerapi.post(`passport/register/smscode/${mobile}?${qs.stringify(params)}`, undefined, {
    handleCatch: true,
  });
};

export const registerByMobile = (params: RegisterParams) => {
  return buyerapi.post(`passport/register/pc`, undefined, {
    handleCatch: true,
    body: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const validMobileSms = ({ mobile, sms_code, scene }: { mobile: string; sms_code: string; scene: string }) => {
  return buyerapi.get(`passport/smscode/${mobile}`, { sms_code, scene }, { handleCatch: true });
};

export const updatePassword = (params: { uuid: string; password: string }) => {
  return buyerapi.put('passport/find-pwd/update-password', undefined, {
    handleCatch: true,
    body: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const resetPasswordValidate = (params: { uuid: string; sms_code: string }) => {
  return buyerapi.get(`passport/find-pwd/valid?${qs.stringify(params)}`, undefined, { handleCatch: true });
};

export const checkAccountExists = (mobile: string) => {
  return buyerapi.get(`passport/mobile/${mobile}`, undefined, { handleCatch: true });
};

export const getResetPasswordCaptcha = (params: { uuid: string; captcha: string; scene: string; mobile: string }) => {
  return buyerapi.post(`passport/find-pwd/send?${qs.stringify(params)}`, undefined, {
    handleCatch: true,
  });
};

export const loginWithAuthenticationCode = (params: { code: string; uuid: string }) => {
  return buyerapi.get(`wechat/olat/login/`, params, { handleCatch: true });
};

export const bindCurrentAccountPhone = (params: BindPhoneParams) => {
  return buyerapi.post(`passport/register-or-bind?${qs.stringify(params)}`, undefined, {
    checkAuth: false,
    handleCatch: true,
    headers: { uuid: params.uuid },
  });
};
