import {
  Button,
  Checkbox,
  Dialog,
  Form,
  Image,
  Input,
  Modal,
  NoticeBar,
  Popup,
  Result,
  SpinLoading,
  Toast,
} from 'antd-mobile';
import {
  DownlandOutline,
  InformationCircleOutline,
  LeftOutline,
  QuestionCircleOutline,
  RightOutline,
} from 'antd-mobile-icons';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

import { PlainAmount } from '@/components/Utils';
import { StorageKey } from '@/configs/enum';
import { fileDownload } from '@/utils';

import {
  createOrUpdateOrderCofirm,
  getOrderHashContract,
  getPhoneCaptcha,
  loginWithCaptcha,
  postOrderHashContract,
  queryArticlesByPosition,
  queryInfoByShort,
  queryOrderCofirm,
  queryUserInfo,
} from './services';

import './style.scss';

export enum StepKey {
  LOGIN,
  ORDER,
  CONTRACT,
  SUCCESSFUL,
  CONFIRMED,
  INVALID,
}
export interface RecordParams {
  member_active?: number;
  order_cofirm?: number;
  cont_confirm?: number;
  requirements_confirm?: number;
}
let timeChange: NodeJS.Timeout;
let confirmTimeChange: NodeJS.Timeout;

const explainText =
  '哈希值起源于1953年，IBM举行的一次历史性的讨论——搜索数据库中的文件，即每个文件都带有电子指纹，相当于一个人的指纹；只要搜索到电子指纹，就会搜索到文件。随着互联网、移动互联网、区块链技术发展，哈希值发挥“唯一性、不可逆性”技术特征广泛应用各场景。';

export const ConfirmedResult = ({ order, trade }: { order?: Record<string, any>; trade?: Record<string, any> }) => {
  return (
    <div className="confirmed">
      <div className="result">
        <Image src={require('./images/confirmed.png')} />
        <h3>订单已确认</h3>
        <p>该订单已经确认了，若要查看订单详情请点击下方按钮登录查看</p>
      </div>
      <div className="options">
        {order?.pay_status === 'PAY_NO' && (
          <Button
            color="danger"
            className="detail-pay"
            style={{ marginLeft: 6, marginRight: 6 }}
            onClick={() => {
              window.location.href = `/order-module/cashier/cashier?trade_sn=${trade?.trade_sn}&trade=true&trade_recode=${trade?.pay_bill?.trade_record_id}`;
            }}
          >
            去支付
          </Button>
        )}
        <Button
          color="primary"
          className="detail-btn"
          style={{ marginLeft: 6, marginRight: 6 }}
          onClick={() => {
            const token = localStorage.getItem(StorageKey.ACCESS_TOKEN);
            window.location.href = token ? `/order-module/order/order-detail?orderSn=${order?.sn}` : '/login';
          }}
        >
          订单详情
        </Button>
      </div>

      <p>
        关注下方 <span className="primary">知淼淼公众号</span>
        <br />
        随时接收或查看服
      </p>
      <div className="qrcode">
        <Image src={require('./images/qrcode.png')} width={125} height={125} />
      </div>
    </div>
  );
};

export const InvalidResult = () => {
  return (
    <div className="confirmed" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Result status="info" title="温馨提示" description="合同已发生变动，请联系您的专属商务顾问" />
    </div>
  );
};

export const SuccessfulResult = ({ order, trade }: { order?: Record<string, any>; trade?: Record<string, any> }) => {
  return (
    <div className="confirmed">
      <div className="result">
        <Image src={require('./images/confirmed.png')} />
        <h3>提交成功</h3>
        <p>您的订单已确认成功，您可以点击下方按钮查看订单详情</p>
      </div>
      <div className="options">
        {order?.pay_status === 'PAY_NO' && (
          <Button
            color="danger"
            className="detail-pay"
            style={{ marginLeft: 6, marginRight: 6 }}
            onClick={() => {
              window.location.href = `/order-module/cashier/cashier?trade_sn=${trade?.trade_sn}&trade=true&trade_recode=${trade?.pay_bill?.trade_record_id}`;
            }}
          >
            去支付
          </Button>
        )}

        <Button
          color="primary"
          className="detail-btn"
          style={{ marginLeft: 6, marginRight: 6 }}
          onClick={() => {
            const token = localStorage.getItem(StorageKey.ACCESS_TOKEN);
            window.location.href = token ? `/order-module/order/order-detail?orderSn=${order?.sn}` : '/login';
          }}
        >
          订单详情
        </Button>
      </div>

      <p>
        关注下方 <span>知淼淼公众号</span>
        <br />
        随时接收或查看服
      </p>
      <div className="qrcode">
        <Image src={require('./images/qrcode.png')} width={125} height={125} />
      </div>
    </div>
  );
};

const Container = () => {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [explainVisible, setExplainVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState<boolean>(false);
  const [currentStepKey, setCurrentStepKey] = useState<StepKey>(StepKey.LOGIN);
  const [searchParams] = useSearchParams();
  const [privacy, setPrivacy] = useState<any>();
  const [trade, setTrade] = useState<Record<string, any>>();
  const [order, setOrder] = useState<Record<string, any>>();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [contractVisible, setContractVisible] = useState<boolean>(false);
  const [contracts, setContracts] = useState<Record<string, any>[]>([]);
  const [currentContract, setCurrentContract] = useState<Record<string, any>>();
  const [confirmOrder, setConfirmOrder] = useState<Record<string, any>>();
  const [currentHash, setCurrentHash] = useState<string>('');
  const [invalidVisible, setInvalidVisible] = useState<boolean>(false);
  const [agreementVisible, setAgreementVisible] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<Record<string, any>>();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false);

  const [time, setTime] = useState(60);
  const [confirmTime, setConfirmTime] = useState(10);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState('获取验证码');
  const [confirmBtnContent, setConfirmBtnContent] = useState('确  认');

  const startCountdown = () => {
    timeChange = setInterval(() => setTime((t) => --t), 1000);
    setBtnDisabled(true);
  };

  // const startConfirmCountdown = () => {
  //   confirmTimeChange = setInterval(() => setConfirmTime((t) => --t), 1000);
  //   setConfirmBtnDisabled(true);
  // };

  const recordConfirmNode = React.useCallback(
    async (params: RecordParams) => {
      const p = confirmOrder ? { ...confirmOrder, ...params } : params;
      const response = await createOrUpdateOrderCofirm({ order_sn: order?.sn, ...p });
      if (response?.code === 200) {
        if (confirmOrder) {
          setConfirmOrder((state) => ({ ...state, ...params }));
        } else {
          const result = await queryOrderCofirm(order?.sn);
          if (result?.code === 200) {
            setConfirmOrder(result.data);
          }
        }
      } else {
        Toast.show({ content: response?.message || '系统异常，请稍后重试' });
      }
    },
    [order, confirmOrder]
  );

  // const checkAndCallConfirmationView = React.useCallback(() => {
  //   if (confirmation && !confirmOrder?.requirements_confirm) {
  //     setConfirmationVisible(true);
  //     confirmTimeChange = setInterval(() => setConfirmTime((t) => --t), 1000);
  //     setConfirmBtnDisabled(true);
  //   }
  // }, [confirmOrder, confirmation]);

  const createOrderHashContract = async (order_sn: string) => {
    const result = await postOrderHashContract({ order_sn });
    if (result?.code !== 200) {
      Toast.show({ content: result?.message || '系统异常，请稍后重试' });
    }
  };

  const queryOrderHashContract = async (order_sn: string) => {
    setFetching(true);
    const result = await getOrderHashContract({ order_sn });
    setFetching(false);
    if (result?.code === 200) {
      setContracts(result.data);
      const confirmation = result.data?.find((item: any) => item.bz_type === 1);
      if (confirmation) {
        setConfirmation(confirmation);
      }
    } else {
      Toast.show({ content: result?.message || '系统异常，请稍后重试', icon: 'fail' });
    }
  };

  const handleGetPhoneCaptcha = React.useCallback(async () => {
    const result = await getPhoneCaptcha({ uuid: localStorage.getItem(StorageKey.UUID) });
    if (result?.code === 200) {
      startCountdown();
      Toast.show({ content: '验证码发送成功！' });
    } else {
      Toast.show({ content: result?.message || '验证码获取错误', icon: 'fail' });
    }
  }, []);

  const getArticlesByPosition = async () => {
    const response = await queryArticlesByPosition('REGISTRATION_AGREEMENT');
    if (response) {
      setPrivacy(response);
    }
  };

  const getInfoByShort = React.useCallback(async () => {
    const uuid = localStorage.getItem(StorageKey.UUID) || uuid4();
    const su = searchParams.get('su');
    const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
    if (su) {
      const response = await queryInfoByShort({ uuid, su });
      if (response?.code === 410) {
        setCurrentStepKey(StepKey.INVALID);
        setInvalidVisible(true);
        return;
      }
      if (response?.code === 200) {
        setTrade(response.data);
        const orderitem = response.data?.order?.[0];
        setOrder(orderitem);
        setProducts(response.data?.order?.[0]?.order_sku_list || []);
        // setContracts(response.data?.cont_preview_list || []);
        setCurrentContract(response.data?.cont_preview_list?.[0]);
        setPhoneNumber(response.data.member_mobile);
        if (orderitem) {
          const result = await queryOrderCofirm(orderitem.sn);
          if (result?.code === 200) {
            setConfirmOrder(result.data);
            if (accessToken) {
              if (result.data?.cont_confirm) {
                setCurrentStepKey(StepKey.CONFIRMED);
                return;
              }
              if (result.data?.order_cofirm) {
                await queryOrderHashContract(orderitem.sn);
                setCurrentStepKey(StepKey.CONTRACT);
                return;
              }
              if (result.data?.member_active) {
                setCurrentStepKey(StepKey.ORDER);
                return;
              }
              if (response.data?.member_mobile && !response.data?.member_mobile.includes('***')) {
                await createOrderHashContract(orderitem.sn);
                setCurrentStepKey(StepKey.ORDER);
                return;
              }
            }
          }
        }
      } else {
        Toast.show(response?.message);
      }
    }
  }, [searchParams]);

  const getUserInfo = async () => {
    const response = await queryUserInfo();
    if (response && !response.code) {
      localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(response));
      // setCurrentUser(response)
      // setCurrentStepKey(StepKey.ORDER);
    }
  };

  const handleSubmit = React.useCallback(
    async (order_sn) => {
      const vals = await form.validateFields();
      if (vals) {
        if (!vals.sms_code) {
          Toast.show({ content: '请输入验证码!' });
          return;
        }
        if (!checkedPrivacy) {
          Toast.show({ content: '请勾选并同意《用户服务协议》' });
          return;
        }
        try {
          setSubmitting(true);
          const params = { ...vals, client: 'WAP', uuid: localStorage.getItem(StorageKey.UUID) };
          const response = await loginWithCaptcha(params);
          if (response?.code === 200) {
            localStorage.setItem(StorageKey.ACCESS_TOKEN, response.data.access_token);
            localStorage.setItem(StorageKey.REFRESH_TOKEN, response.data.refresh_token);
            await getInfoByShort();
            await createOrderHashContract(order_sn);
            setCurrentStepKey(StepKey.ORDER);
            recordConfirmNode({ member_active: 1 });
          }
        } catch (error: any) {
          Toast.show({ content: error?.message || '系统异常' });
        } finally {
          setSubmitting(false);
        }
      }
    },
    [form, checkedPrivacy, getInfoByShort, recordConfirmNode]
  );

  useEffect(() => {
    if (!localStorage.getItem(StorageKey.UUID)) {
      localStorage.setItem(StorageKey.UUID, uuid4());
    }
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
      setLoading(true);
      if (accessToken) {
        await getUserInfo();
      }
      await Promise.all([getInfoByShort(), getArticlesByPosition()]);
      setLoading(false);
    })();
  }, [getInfoByShort]);

  useEffect(() => {
    return () => {
      clearInterval(timeChange);
    };
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(confirmTimeChange);
    };
  }, []);

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent('获取验证码');
    }
  }, [time]);

  useEffect(() => {
    if (confirmTime > 0 && confirmTime < 10) {
      setConfirmBtnContent(`(${confirmTime}s)请认真核对服务项目`);
    } else {
      clearInterval(confirmTimeChange);
      setConfirmBtnDisabled(false);
      setConfirmTime(10);
      setConfirmBtnContent('确  认');
    }
  }, [confirmTime]);

  useEffect(() => {
    if (currentStepKey === StepKey.CONTRACT && confirmation && confirmOrder?.requirements_confirm === 0) {
      setConfirmationVisible(true);
      confirmTimeChange = setInterval(() => setConfirmTime((t) => --t), 1000);
      setConfirmBtnDisabled(true);
    }
  }, [currentStepKey, confirmOrder, confirmation]);

  return (
    <div className="confirm-content">
      {loading ? (
        <div className="loading-section">
          <SpinLoading color="#007AFF" />
        </div>
      ) : (
        <>
          {currentStepKey < StepKey.SUCCESSFUL && (
            <div className="steps-wrapper">
              <div className="steps">
                <div className={`index active`}>
                  <strong>1</strong>
                  <div className="desc">验证手机号</div>
                </div>
                <div className={`line ${currentStepKey > StepKey.LOGIN && 'active'}`}></div>
                <div className={`index ${currentStepKey > StepKey.LOGIN && 'active'}`}>
                  <strong>2</strong>
                  <div className="desc">订单详情</div>
                </div>
                <div className={`line  ${currentStepKey === StepKey.CONTRACT && 'active'}`}></div>
                <div className={`index ${currentStepKey === StepKey.CONTRACT && 'active'}`}>
                  <strong>3</strong>
                  <div className="desc">合同信息</div>
                </div>
              </div>
            </div>
          )}

          {currentStepKey === StepKey.LOGIN && (
            <div className="login-card">
              <h5>验证手机号</h5>
              <strong>{phoneNumber}</strong>
              <Form form={form} layout="vertical" onFinish={() => handleSubmit(order?.sn)}>
                <Form.Item
                  label=""
                  name="sms_code"
                  style={{ borderBottom: '1px solid #ddd', paddingRight: 0 }}
                  extra={
                    <Button
                      color="primary"
                      style={{ fontSize: 14 }}
                      disabled={btnDisabled}
                      fill="none"
                      onClick={() => handleGetPhoneCaptcha()}
                    >
                      {btnContent}
                    </Button>
                  }
                >
                  <Input clearable />
                </Form.Item>
                <div className="checkbox" style={{ padding: '10px 0' }}>
                  <Checkbox
                    onChange={(checked) => {
                      setCheckedPrivacy(checked);
                    }}
                  >
                    <span style={{ fontSize: 14 }}>我已阅读并同意</span>
                    <Button
                      color="primary"
                      fill="none"
                      style={{ fontSize: 14, padding: 0 }}
                      onClick={() => {
                        setAgreementVisible(true);
                      }}
                    >
                      《用户服务协议》
                    </Button>
                  </Checkbox>
                </div>
                <div className="operations" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    color="primary"
                    type="submit"
                    loading={submitting}
                    style={{ width: 250, height: 44, borderRadius: 22, fontWeight: 'bold' }}
                  >
                    确定
                  </Button>
                </div>
              </Form>
            </div>
          )}
          {currentStepKey === StepKey.ORDER && (
            <div className="order-card">
              <div className="order-header">
                <h3 className="title">商品</h3>
              </div>
              <div className="products">
                {products.map((item) => (
                  <div className="product-item" key={item.sku_id}>
                    <div className="left">
                      <img src={item.goods_image} alt="" />
                    </div>
                    <div className="right">
                      <div className="info">
                        <div className="name">{item.name}</div>
                        {item.spec_list && (
                          <div className="skus">
                            {item.spec_list.map((item: any) => `${item.spec_name}:${item.spec_value}`).join('；')}
                          </div>
                        )}
                      </div>
                      <div className="price">
                        <small>x{item.num}</small>
                        <small style={{ fontSize: 12 }}>
                          实付:
                          <PlainAmount value={Number(item.purchase_subtotal)} size={18} color="#333" />
                        </small>
                      </div>
                    </div>
                  </div>
                ))}
                {products.length > 1 && (
                  <div className="products-footer">
                    <span>合计:</span>
                    {/* <big>{order?.goods_price}</big> */}
                    <PlainAmount value={Number(order?.order_price)} size={18} color="#333" />
                  </div>
                )}
              </div>
            </div>
          )}
          {currentStepKey === StepKey.CONTRACT && (
            <>
              <NoticeBar
                // extra={<DownOutline style={{ fontSize: 18 }} onClick={() => {
                //   setExplainVisible(true);
                // }} />}
                icon={<InformationCircleOutline />}
                color="info"
                content={'合同确认无误后将委托杭州互联网公证处保管'}
              />
              {/* {contracts.length === 1 && (
                <div className="contract-card">
                  <div className="contract-header">
                    <h3 className="title">{currentContract?.title}</h3>
                  </div>
                  <div className="contracts">
                    <div className="content">
                      {currentContract?.img_url_list.map((img: string) => (
                        <img src={img} key={img} alt={img} />
                      ))}
                    </div>
                  </div>
                </div>
              )} */}
              <div className="contract-card">
                <div className="contract-header">
                  <h3 className="title">合同</h3>
                </div>
                {fetching ? (
                  <div className="loading-section">
                    <SpinLoading color="#007AFF" />
                  </div>
                ) : (
                  <div className="list">
                    {contracts.map((item) => (
                      <div
                        className="list-item"
                        key={item.con_no}
                        onClick={() => {
                          setCurrentContract(item);
                          setContractVisible(true);
                        }}
                      >
                        <div className="list-item-main">
                          <div className="left">
                            <strong>{item.title}</strong>
                          </div>
                          {!item.hash && (
                            <DownlandOutline
                              style={{ fontSize: 16, marginLeft: 10, color: '#007bff' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                fileDownload(item.url);
                              }}
                            />
                          )}
                          {/* <div className="right">
                          <RightOutline style={{ fontSize: 16, color: '#333', fontWeight: 'bold' }} />
                        </div> */}
                        </div>
                        {item.hash && (
                          <div className="list-item-footer">
                            <div className="hash">
                              <strong>Hash:</strong>
                              <small>{item.hash}</small>
                              <QuestionCircleOutline
                                style={{ fontSize: 16, marginLeft: 5, color: '#007bff' }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setCurrentHash(item.hash);
                                  setExplainVisible(true);
                                }}
                              />
                              <DownlandOutline
                                style={{ fontSize: 16, marginLeft: 10, color: '#007bff' }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  fileDownload(item.url);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <RightOutline
                          style={{
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            margin: 'auto',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {currentStepKey === StepKey.ORDER && (
            <div className="view-footer">
              <Button
                className="submit"
                onClick={async () => {
                  setCurrentStepKey(StepKey.CONTRACT);
                  recordConfirmNode({ order_cofirm: 1 });
                  if (order) {
                    await queryOrderHashContract(order.sn);
                  }
                  if (confirmation && !confirmOrder?.requirements_confirm) {
                    setConfirmationVisible(true);
                    confirmTimeChange = setInterval(() => setConfirmTime((t) => --t), 1000);
                    setConfirmBtnDisabled(true);
                  }
                  // checkAndCallConfirmationView();
                }}
              >
                确认无误，查看下一项
              </Button>
            </div>
          )}
          {currentStepKey === StepKey.CONTRACT && (
            <div className="view-footer">
              <Button
                className="submit"
                onClick={async () => {
                  Dialog.confirm({
                    content: '合同确认无误后将委托杭州互联网公证处保管',
                    onConfirm: async () => {
                      await recordConfirmNode({ cont_confirm: 1 });
                      setCurrentStepKey(StepKey.SUCCESSFUL);
                    },
                  });
                }}
              >
                确认无误
              </Button>
            </div>
          )}
          {currentStepKey === StepKey.SUCCESSFUL && <SuccessfulResult order={order} trade={trade} />}
          {currentStepKey === StepKey.CONFIRMED && <ConfirmedResult order={order} />}
          {currentStepKey === StepKey.INVALID && <InvalidResult />}
        </>
      )}

      <Popup
        visible={agreementVisible}
        onMaskClick={() => {
          setAgreementVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          maxHeight: '70vh',
        }}
      >
        <div style={{ padding: 10, overflowY: 'scroll', maxHeight: '70vh' }}>
          <div dangerouslySetInnerHTML={{ __html: privacy?.content }} />
        </div>
      </Popup>
      {/* <Popup
        visible={confirmationVisible}
        onMaskClick={() => {
          setConfirmationVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          maxHeight: '70vh',
        }}
      >
        <div style={{ padding: 10, overflowY: 'scroll', maxHeight: '70vh' }}>
          <div dangerouslySetInnerHTML={{ __html: privacy?.content }} />
        </div>
      </Popup>
       */}
      <Popup
        visible={confirmationVisible}
        onMaskClick={() => {
          setConfirmationVisible(false);
        }}
        mask={false}
        bodyStyle={{ width: '100vw' }}
      >
        <div className="requirements">
          <div className="header">
            <div className="title">{confirmation?.title}</div>
          </div>
          <div className={`content popup`}>
            {confirmation?.img_url_list.map((img: string) => (
              <img src={img} key={img} alt={img} />
            ))}
          </div>
          <div className="footer">
            <Button
              color="primary"
              type="submit"
              style={{ width: 250, height: 44, borderRadius: 22, fontWeight: 'bold' }}
              disabled={confirmBtnDisabled}
              onClick={async () => {
                await recordConfirmNode({ requirements_confirm: 1 });
                setConfirmationVisible(false);
              }}
            >
              {confirmBtnContent}
            </Button>
          </div>
        </div>
      </Popup>
      <Popup
        visible={contractVisible}
        onMaskClick={() => {
          setContractVisible(false);
        }}
        position="right"
        mask={false}
        bodyStyle={{ width: '100vw' }}
      >
        <div className="contracts">
          <div className="header">
            <div
              className="back"
              onClick={() => {
                setContractVisible(false);
              }}
            >
              <LeftOutline style={{ fontSize: 20, color: '#333', fontWeight: 'bold' }} />
            </div>
            <div className="title">{currentContract?.title}</div>
            <div className="empty" />
          </div>
          <div className={`content popup`}>
            {currentContract?.img_url_list.map((img: string) => (
              <img src={img} key={img} alt={img} />
            ))}
          </div>
        </div>
      </Popup>
      <Popup
        visible={explainVisible}
        onMaskClick={() => {
          setExplainVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
      >
        <p className="explain-content">Hash: {currentHash}</p>
        <p className="explain-content">{explainText}</p>
      </Popup>
      <Modal
        visible={invalidVisible}
        content={<Result status="info" title="温馨提示" description="合同已发生变动，请联系您的专属商务顾问" />}
        closeOnAction={false}
        closeOnMaskClick={false}
        actions={[]}
      />
      {/* <Modal
        visible={confirmationVisible}
        content={
          <div className="contracts">
            <div className="header">
              <div className="title">{confirmation?.title}</div>
            </div>
            <div className={`content popup`}>
              {confirmation?.img_url_list.map((img: string) => (
                <img src={img} key={img} alt={img} />
              ))}
            </div>
          </div>
        }
        closeOnAction={false}
        closeOnMaskClick={false}
        actions={[
          {
            key: 'confirm',
            disabled: confirmBtnDisabled,
            text: `(${confirmTime}s)确认`,
            primary: true,
            onClick: async () => {
              await recordConfirmNode({ requirements_confirm: 1 });
              setConfirmationVisible(false);
            },
          },
        ]}
      /> */}
    </div>
  );
};

export default Container;
