export enum StorageKey {
  UUID = 'uuid',
  ULID = 'ULID',
  CURRENT_USER = 'CURRENT_USER',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  AUTHORIZING = 'AUTHORIZING',
}

export enum OrderStatus {
  NEW = 'NEW',
  INTODB_ERROR = 'INTODB_ERROR',
  BALANCE_ERROR = 'BALANCE_ERROR',
  CONFIRM = 'CONFIRM',
  PAID_OFF = 'PAID_OFF',
  PAID_W_CONFIRM = 'PAID_W_CONFIRM',
  FORMED = 'FORMED',
  SHIPPED = 'SHIPPED',
  ROG = 'ROG',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  PAY_PARTIAL = 'PAY_PARTIAL',
  AFTER_SERVICE = 'AFTER_SERVICE',
}

export enum Environment {
  MP = 'MP',
  MINIAPP = 'MINIAPP',
  BROWSER = 'BROWSER',
  APP = 'APP',
}

export enum TabKey {
  HOME = 'HOME',
  ACTIVITY = 'ACTIVITY',
  ORDER = 'ORDER',
  MEMBER = 'MEMBER',
}
