import { Form, Input, Modal, Toast } from 'antd-mobile';
import React, { useState } from 'react';
import { createConsult } from '../services';

const SaasConsult: React.FC<{
  visible: boolean;
  closeModal: () => void;
  current?: string;
}> = ({ visible, closeModal, current }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    const vals = await form.validateFields();
    if (vals && current && !submitting) {
      const baseParams = {
        type: 12,
        industry_name: '服务体系认证',
        source: 'WAP-首页',
        keyword: `[服务体系认证]资质类别:${current},联系人:${vals.uname},联系电话:${vals.phone}`,
      };
      setSubmitting(true);
      const result = await createConsult({
        ...baseParams,
        ...vals,
      });
      setSubmitting(false);
      if (result?.id) {
        Toast.show({ icon: 'success', content: result.message || '提交成功' });
        closeModal();
      } else {
        Toast.show({ icon: 'fail', content: result.message || '系统繁忙，请稍后尝试' });
      }
    }
  };
  return (
    <Modal
      visible={visible}
      title="服务体系认证"
      closeOnMaskClick
      showCloseButton
      content={
        <Form form={form} layout="horizontal" style={{ margin: '20px 0 10px' }}>
          <Form.Item label={<span style={{ fontSize: 14 }}>服务名称</span>}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: '26px',
              }}
            >
              <span style={{ fontSize: 14, width: '100%', textAlign: 'left' }}>{current}</span>
            </div>
          </Form.Item>
          <Form.Item
            name="uname"
            label={<span style={{ fontSize: 14 }}>联系人</span>}
            rules={[{ required: true, message: '请输入联系人' }]}
          >
            <Input placeholder="我们该如何称呼您" style={{ fontSize: 14 }} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={<span style={{ fontSize: 14 }}>联系电话</span>}
            rules={[{ required: true, message: '请输入联系电话' }]}
          >
            <Input placeholder="用于接收查询结果" style={{ fontSize: 14 }} />
          </Form.Item>
        </Form>
      }
      // closeOnAction
      onClose={() => {
        closeModal();
      }}
      actions={[
        {
          key: 'confirm',
          text: '立即咨询',
          primary: true,
          className: 'border-radius',
          disabled: submitting,
          onClick: () => {
            handleSubmit();
          },
        },
      ]}
    />
  );
};

export default SaasConsult;
