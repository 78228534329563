import { SpinLoading } from 'antd-mobile';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { StorageKey } from '@/configs/enum';
import CurrentUserContext from '@/contexts/CurrentUserContext';
import { queryUserInfo } from '@/services/common';
import { wechatAuthentication } from '@/utils';

import { loginWithAuthenticationCode } from './service';

const Container = () => {
  const [searchParams] = useSearchParams();
  const { setCurrentUser } = React.useContext(CurrentUserContext);

  React.useEffect(() => {
    const queryCurrentMember = async (params: { code: string; uuid: string }) => {
      const response = await loginWithAuthenticationCode(params);
      // localStorage.removeItem(StorageKey.AUTHORIZING);
      if (response?.code === 200) {
        if (response.data.is_bind) {
          localStorage.setItem(StorageKey.ACCESS_TOKEN, response.data?.access_token);
          localStorage.setItem(StorageKey.REFRESH_TOKEN, response.data?.refresh_token);
          const result = await queryUserInfo();
          if (result && !response.code) {
            setCurrentUser?.(result);
            localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(result));
          }
          window.location.href = searchParams.get('redirect') || '/';
        } else {
          window.location.href = '/member/bind-phone?redirect=' + searchParams.get('redirect') || '/';
        }
      }
      if (response?.code === 500) {
        localStorage.removeItem(StorageKey.ULID);
        wechatAuthentication('/');
      }
    };

    const code = searchParams.get('code');
    const uuid = localStorage.getItem(StorageKey.ULID);
    if (code && uuid) {
      queryCurrentMember({ code, uuid });
    }
  }, [searchParams, setCurrentUser]);

  return (
    <div style={{ minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span style={{ fontSize: 18 }}>
        <SpinLoading color="#007AFF" />
      </span>
    </div>
  );
};

export default Container;
