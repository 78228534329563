import { Popup, SpinLoading } from 'antd-mobile';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

import { StorageKey } from '@/configs/enum';

import { queryContractByShortUrl } from './services';

import './style.scss';

const Container = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setAgreements] = useState<Record<string, any>[]>([]);
  const [searchParams] = useSearchParams();
  const [currentContract, setCurrentContract] = useState<Record<string, any>>();
  const [contractVisible, setContractVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!localStorage.getItem(StorageKey.UUID)) {
      localStorage.setItem(StorageKey.UUID, uuid4());
    }
  }, []);

  useEffect(() => {
    (async () => {
      const short_url = searchParams.get('su');
      if (short_url) {
        setLoading(true);
        const response = await queryContractByShortUrl(short_url);
        setLoading(false);
        if (response?.code === 200) {
          setAgreements(response.data);
        }
      }
    })();
  }, [searchParams]);

  return (
    <div className="confirm-content">
      {loading ? (
        <div className="loading-section">
          <SpinLoading color="#007AFF" />
        </div>
      ) : (
        <>
          {contracts.length === 1 ? (
            <div className="contract-card">
              <div className="contract-header">
                <h3 className="title">{contracts[0].template_name}</h3>
              </div>
              <div className="content">
                {contracts[0].img_path
                  ? JSON.parse(contracts[0].img_path).map((img: string) => <img src={img} key={img} alt={img} />)
                  : null}
                {/* {contracts.map((item) => (
                  <div
                    className="list-item"
                    key={item.template_id}
                    onClick={() => {
                      setCurrentContract(item);
                      setContractVisible(true);
                    }}
                  >
                    <div className="list-item-main">
                      <div className="left">
                        <strong>{item.template_name}</strong>
                      </div>
                    </div>
                    <RightOutline
                      style={{
                        fontSize: 16,
                        color: '#333',
                        fontWeight: 'bold',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                      }}
                    />
                  </div>
                ))} */}
              </div>
            </div>
          ) : (
            <div className="contract-card">
              <div className="contract-header">
                <h3 className="title">合同</h3>
              </div>
              <div className="list">
                {contracts.map((item) => (
                  <div
                    className="list-item"
                    key={item.template_id}
                    onClick={() => {
                      setCurrentContract(item);
                      setContractVisible(true);
                    }}
                  >
                    <div className="list-item-main">
                      <div className="left">
                        <strong>{item.template_name}</strong>
                      </div>
                    </div>
                    <RightOutline
                      style={{
                        fontSize: 16,
                        color: '#333',
                        fontWeight: 'bold',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      <Popup
        visible={contractVisible}
        onMaskClick={() => {
          setContractVisible(false);
        }}
        position="right"
        mask={false}
        bodyStyle={{ width: '100vw' }}
      >
        <div className="contracts">
          <div className="header">
            <div
              className="back"
              onClick={() => {
                setContractVisible(false);
              }}
            >
              <LeftOutline style={{ fontSize: 20, color: '#333', fontWeight: 'bold' }} />
            </div>
            <div className="title">{currentContract?.template_name}</div>
            <div className="empty" />
          </div>
          <div className={`content popup`}>
            {currentContract?.img_path
              ? JSON.parse(currentContract.img_path).map((img: string) => <img src={img} key={img} alt={img} />)
              : null}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Container;
