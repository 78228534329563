import { advertapi, buyerapi } from '@/utils/request';

export const syncAccessShortLink = (params: { su: string; uuid: string }) => {
  return buyerapi.get('distribution/su/visit', params, {
    headers: { uuid: params.uuid },
  });
};

export const queryAdvertisingPositionById = (idx: number) => {
  return advertapi.get(`advertisingPosition/selectAllByPositionId/${idx}`);
};


export const queryAdvertisingPosition = (code: string) => {
  return advertapi.get(`advertisingPosition/code/${code}`);
};

export const getIndustryTree = () => {
  return advertapi.get('api/base/tree/selectAllByType?type=6');
};

export const createConsult = (params: Record<string, any>) => {
  return buyerapi.post('require/create', params, { handleCatch: true });
};
