import { Empty, Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import moment from 'moment';
import BasicLayout from '@/layouts/basic';

import { queryActivities } from './services';

import './style.scss';
import { TabKey } from '@/configs/enum';

enum ActivityStatus {
  ONGOING = 'ONGOING',
  NOT_BEGUN = 'NOT_BEGUN',
  FINISHED = 'FINISHED',
}

const Container = () => {
  const [activities, setActivities] = useState<Record<string, any>[]>([]);
  const fetchActivities = async () => {
    const response = await queryActivities();
    if (response?.code === 200) {
      setActivities(response.data || []);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  return (
    <BasicLayout current={TabKey.ACTIVITY}>
      <div className="activity-content">
        {activities.length > 0 ? (
          <>
            <ul>
              {activities.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    if (item.wap_site) {
                      if (item.status === ActivityStatus.NOT_BEGUN) {
                        return Toast.show({ content: '活动未开始，请等候！' });
                      }
                      if (item.status === ActivityStatus.FINISHED) {
                        return Toast.show({ content: '活动已结束！' });
                      }
                      window.location.href = `${item.wap_site}?referrer=/activity`;
                    }
                  }}
                >
                  <img src={item.wap_poster} alt="" />
                  <div className="item-footer">
                    <div className="activate-date">
                      活动时间：{moment(item.begin_time).format('YYYY-MM-DD')}至
                      {moment(item.end_time).format('YYYY-MM-DD')}
                    </div>
                    <div className="status">
                      {item.status === ActivityStatus.ONGOING && (
                        <span className="active">
                          <i></i> 服务中
                        </span>
                      )}
                      {item.status === ActivityStatus.NOT_BEGUN && <span>未开始</span>}
                      {item.status === ActivityStatus.FINISHED && <span className="disabled">已结束</span>}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div
              style={{
                color: '#999',
                height: 30,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              - 没有更多啦 -
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 300,
            }}
          >
            <Empty description="暂时没有活动开展~" />
          </div>
        )}
      </div>
    </BasicLayout>
  );
};

export default Container;
