// import { RightOutline } from 'antd-mobile-icons';
// import { useNavigate } from 'react-router-dom';

import { Amount } from '@/components/Utils';
import { getVariantName } from '@/utils';

import './style.scss';

export interface ComponentProps {
  products?: Record<string, any>[];
  worksheets?: Record<string, any>[];
}

const Component = ({ products }: ComponentProps) => {
  // const navigate = useNavigate();

  return (
    <div className="order-product-box">
      {products?.map((product: Record<string, any>, idx: number) => (
        <div key={product.sku_id} className={`product-cell ${idx === 0 ? '' : 'border'}`}>
          <div className="cell-left">
            <span className="cell-title" dangerouslySetInnerHTML={{ __html: product.name }} />
            <div className="attr">
              <span style={{ fontSize: 12, color: '#666' }}>
                {product.spec_list ? getVariantName(product.spec_list) : '默认版'}
              </span>
            </div>
          </div>
          <div className="cell-right">
            <div className="cell-price">
              <Amount value={product.purchase_price} size={18} color="#333" />
            </div>
            <span className="total">x {product.num}</span>
          </div>
          {/* <div
            className="product-cell-bottom"
            onClick={() => {
              navigate('/product/detail/' + product.work_order_id);
            }}
          >
            <span style={{ fontSize: 12, color: '#666', lineHeight: '24px' }}>{product?.node_desc}</span>
            <span>
              <small style={{ color: '#007bff' }}>工单详情</small>
              <RightOutline className={`arrow right`} style={{ fontSize: 12, color: '#007bff', paddingTop: 2 }} />
            </span>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default Component;
