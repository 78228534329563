import { Amount } from '@/components/Utils';

import './style.scss';

const Component = ({ products }: { products: Record<string, any>[] }) => {
  return (
    <div className="recommend-section">
      <big>-- 为您推荐 --</big>
      {products?.map((product: Record<string, any>, idx: number) => (
        <div key={product.goods_id} className={`product-cell ${idx === 0 ? '' : 'border'}`} onClick={() => {
          window.location.href = `/goods-module/goods?goods_id=${product.goods_id}&referrer=${window.location.pathname}`
        }}>
          <div className="cell-left">
            <span className="cell-title">{product.goods_name}</span>
          </div>
          <div className="cell-right">
            <div className="cell-price">
              <Amount value={product.price} size={18} color="#ff5e24" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Component;
