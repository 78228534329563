import baseapi, { buyerapi } from "@/utils/request";

export const queryArticlesByPosition = (position: string) => {
  return baseapi.get(`pages/${position}/articles`, undefined, { handleCatch: true });
};


export const queryUserInfo = () => {
  return buyerapi.get('members', undefined, { handleCatch: true });
};
