import baseapi, { buyerapi } from '@/utils/request';
import qs from 'qs';

export const queryActivities = () => {
  return buyerapi.get('activity/list');
};

export const queryUserInfo = () => {
  return buyerapi.get('members', undefined, { handleCatch: true });
};

export const queryInfoByShort = (params: { su: string; uuid: string }) => {
  return buyerapi.get('trade/short/trade', params, { handleCatch: true });
};

export const queryArticlesByPosition = (position: string) => {
  return baseapi.get(`pages/${position}/articles`, undefined, { handleCatch: true });
};

export const queryOrderContracts = (params: Record<string, any>) => {
  return buyerapi.get('trade/short/trade', params);
};

export const getPhoneCaptcha = (params: Record<string, any>) => {
  return buyerapi.post(`passport/send/sms?${qs.stringify(params)}`);
};

export const loginWithCaptcha = (params: Record<string, any>) => {
  return buyerapi.post(`passport/register?${qs.stringify(params)}`);
};

export const createOrUpdateOrderCofirm = (params: Record<string, any>) => {
  return buyerapi.post('order/cofirm/createOrUpdate', params);
};

export const queryOrderCofirm = (idx: string) => {
  return buyerapi.get(`order/cofirm/ordersn/${idx}`, undefined, { handleCatch: true });
};

export const postOrderHashContract = (params: { order_sn: string }) => {
  return buyerapi.post(`buyer/cont-main/preSign?${qs.stringify(params)}`, undefined, { handleCatch: true });
};

export const getOrderHashContract = (params: { order_sn: string }) => {
  return buyerapi.get(`buyer/cont-main/preSign`, params, { handleCatch: true });
};
