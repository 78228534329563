import { ErrorBlock, NavBar, PullToRefresh, SpinLoading } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getVariantName } from '@/utils';

import { getUserWorksheetList } from '../services';

import './style.scss';

const Container = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [worksheets, setWorksheets] = useState<Record<string, any>>([]);

  const navigate = useNavigate();

  const loadWorksheets = async () => {
    setLoading(true);
    const res = await getUserWorksheetList();
    if (res?.code === 200) {
      setWorksheets(res.data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadWorksheets();
  }, []);

  return (
    <div className="worksheet-container">
      <NavBar
        onBack={() => {
          navigate(-1);
        }}
      >
        我的工单
      </NavBar>
      <div className="content">
        <div className="worksheet-list">
          <PullToRefresh
            onRefresh={async () => {
              loadWorksheets();
            }}
          >
            {loading ? (
              <div style={{ minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SpinLoading color="#007AFF" style={{ fontSize: 18 }} />
              </div>
            ) : (
              <div className="worksheet-list-component">
                {worksheets.length > 0 ? (
                  worksheets.map((item: Record<string, any>) => (
                    <div
                      className="worksheet-item"
                      key={item.work_order_id}
                      onClick={() => {
                        navigate('/worksheet/detail/' + item.work_order_id);
                      }}
                    >
                      <div className="worksheet-content">
                        <div key={item.sku_id} className={`product-cell`}>
                          <div className="cell-left">
                            <span className="cell-title">{item.goods_name}</span>
                            <div className="attr">
                              <span style={{ fontSize: 12, color: '#666' }}>
                                {item.goods_info?.spec_json ? getVariantName(item.goods_info.spec_json) : '默认版'}
                              </span>
                            </div>
                            {item?.node_desc && (
                              <div className="process">
                                <span className="process-text">{item.node_desc}</span>
                              </div>
                            )}
                          </div>
                          <div className="cell-right">
                            <RightOutline className={`arrow right`} style={{ fontSize: 20, color: '#999' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ErrorBlock status="empty" title={'暂无数据'} description={null} />
                  </div>
                )}
              </div>
            )}
          </PullToRefresh>
        </div>
      </div>
    </div>
  );
};

export default Container;
