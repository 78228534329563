import { StorageKey } from '@/configs/enum';
import { Toast } from 'antd-mobile';
import { stringify } from 'qs';
import { isWechatEnvironment, wechatAuthentication } from './index';
import { envHosts } from '../configs/host';
import { ALLOW_PATHS } from '@/configs/constant';

type Params = {
  current?: number;
  pageSize?: number;
  [prop: string]: any;
};

type Options = {
  handleCatch?: boolean;
  handleCatchOriginal?: boolean;
  checkAuth?: boolean;
  notify?: boolean;
  withLocation?: boolean;
  body?: Params;
  method?: 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  [prop: string]: any;
};

interface CustomResponse {
  code: number;
  message: string;
  status: number;
  data: any;
  [prop: string]: any;
}

// interface CodeMessage {
//   [prop: string]: string;
// }

interface ErrorResponse {
  status: number;
  message: string;
  [prop: string]: any;
}

// const codeMessage: CodeMessage = {
//   200: "服务器成功返回请求的数据。",
//   201: "新建或修改数据成功。",
//   202: "一个请求已经进入后台排队（异步任务）。",
//   204: "删除数据成功。",
//   400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
//   401: "用户没有权限（令牌、用户名、密码错误）。",
//   403: "用户得到授权，但是访问是被禁止的。",
//   404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
//   406: "请求的格式不可得。",
//   410: "请求的资源被永久删除，且不会再得到的。",
//   422: "当创建一个对象时，发生一个验证错误。",
//   500: "服务器发生错误，请检查服务器。",
//   502: "网关错误。",
//   503: "服务不可用，服务器暂时过载或维护。",
//   504: "网关超时。",
// };

const relogin = () => {
  localStorage.removeItem(StorageKey.ACCESS_TOKEN);
  localStorage.removeItem(StorageKey.REFRESH_TOKEN);
  if (isWechatEnvironment()) {
    localStorage.removeItem(StorageKey.ULID);
    wechatAuthentication(window.location.href);
    return;
  }
  if (!ALLOW_PATHS.includes(window.location.pathname)) {
    window.location.href = '/login';
  }
};

/**
 * 检查http状态值
 * @param response
 * @returns {*}
 */
// function checkHttpStatus(response: Response): any {
//   if (response.status >= 200 && response.status < 300) {
//     return response;
//   } else {
//     const message =
//       codeMessage[response.status] || `ERROR CODE: ${response.status}`;
//     const error = { status: response.status, message };
//     throw error;
//   }
// }

const checkCode = async (response: Response, options: Options) => {
  if (options.handleCatchOriginal) {
    return response;
  }
  const res = await response.json();
  const { code, message } = res;
  if (options.handleCatch) {
    return res;
  }
  if (code > 700 && code < 710) {
    relogin();
  }
  if (code !== 200) {
    const error = { status: code, message };
    throw error;
  } else {
    return res;
  }
};

const handleError = async (err: ErrorResponse, options: Options) => {
  const { status, message } = err;
  // const expireTime = localStorage.getItem('expireTime');
  // const expireTime = await AsyncStorage.getItem("expireTime");
  if (status > 700 && status < 710) {
    relogin();
  } else {
    if (options.notify !== false) {
      Toast.show({ content: message, position: 'top' });
    }
  }
};

export const request = async (url: string, options: Options) => {
  const { method = 'GET', body, ...rest } = options;
  // const _url = url.startsWith("http") ? url : config.domain + url;
  // const token = await AsyncStorage.getItem("token");
  const token = localStorage.getItem(StorageKey.ACCESS_TOKEN);
  const defaultOptions = {
    checkAuth: true,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  };
  if (token && options.checkAuth !== false) {
    Object.assign(defaultOptions, {
      headers: { Authorization: `Bearer ${token}`, ...defaultOptions.headers },
    });
  }
  if (body && Object.keys(body).length && ['GET', 'HEAD'].indexOf(method.toUpperCase()) < 0) {
    Object.assign(defaultOptions, { body: JSON.stringify(body) });
  }
  const opts = { ...defaultOptions, ...rest };
  try {
    const response =
      method?.toUpperCase() === 'GET'
        ? await fetch(body ? `${url}?${stringify(body)}` : url, opts)
        : await fetch(url, { method, ...opts });
    return checkCode(response, opts);
  } catch (error: any) {
    handleError(error, options);
  }
};

export const reqs = (host: string) => {
  // const env = process.env.APP_ENV || 'dev';
  const baseUrl = envHosts[process.env.REACT_APP_ENV || 'development'][host];
  return {
    get(url: string, body?: Options['body'], opts?: Options): Promise<CustomResponse> {
      return request(`${baseUrl}/${url}`, { method: 'GET', body, ...opts });
    },
    post(url: string, body?: Options['body'], opts?: Options): Promise<CustomResponse> {
      return request(`${baseUrl}/${url}`, { method: 'POST', body, ...opts });
    },
    patch(url: string, body: Options['body'], opts?: Options): Promise<CustomResponse> {
      return request(`${baseUrl}/${url}`, { method: 'PATCH', body, ...opts });
    },
    put(url: string, body: Options['body'], opts?: Options): Promise<CustomResponse> {
      return request(`${baseUrl}/${url}`, { method: 'PUT', body, ...opts });
    },
    delete(url: string, body?: Options['body'], opts?: Options): Promise<Response> {
      return request(`${baseUrl}/${url}`, { method: 'DELETE', body, ...opts });
    },
  };
};

export const baseapi = reqs('base');

export const buyerapi = reqs('buyer');

export const advertapi = reqs('advert');

export const wechatapi = reqs('wechat');

export const deliveryapi = reqs('delivery');

export const intelligentapi = reqs('intelligent');

export default baseapi;

// export const req = {
//   get(
//     url: string,
//     body?: Options['body'],
//     opts?: Options
//   ): Promise<CustomResponse> {
//     return request(url, { method: 'GET', body, ...opts });
//   },
//   post(
//     url: string,
//     body?: Options['body'],
//     opts?: Options
//   ): Promise<CustomResponse> {
//     return request(url, { method: 'POST', body, ...opts });
//   },
//   patch(
//     url: string,
//     body: Options['body'],
//     opts?: Options
//   ): Promise<CustomResponse> {
//     return request(url, { method: 'PATCH', body, ...opts });
//   },
//   put(
//     url: string,
//     body: Options['body'],
//     opts?: Options
//   ): Promise<CustomResponse> {
//     return request(url, { method: 'PUT', body, ...opts });
//   },
//   delete(
//     url: string,
//     body?: Options['body'],
//     opts?: Options
//   ): Promise<Response> {
//     return request(url, { method: 'DELETE', body, ...opts });
//   },
// };

// export default req;
