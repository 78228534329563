
import { Button } from 'antd-mobile';
import { CheckOutline } from 'antd-mobile-icons';
import React from 'react';

import './index.css';

interface CheckboxProp {
  disabled?: boolean;
  checked: boolean;
  value?: number | string;
  onClick?: (checked: boolean) => void;
}

const CheckboxComponent: React.FC<CheckboxProp> = ({ disabled = false, checked, onClick }) => {
  return (
    <Button
      className={`custom-checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={() => {
        if (disabled) return;
        if (onClick) onClick(!checked);
      }}
    >
      {checked && <CheckOutline className='check-icon' style={{ fontSize: 12, color: '#fff' }} />}
    </Button>
  );
};

export default  React.memo(CheckboxComponent);
