import { Image, NavBar, Space, SpinLoading } from 'antd-mobile';
import { CalendarOutline, RightOutline } from 'antd-mobile-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImagePreview from '@/components/ImagePreview';
import ListCell from '@/components/ListCell';
import ProcessView from '@/components/ProcessView';
import RecommendSection from '@/components/RecommendSection';
import Section from '@/components/Section';
import ShipProcessView, { ShipProcessViewAction } from '@/components/ShipProcessView';
import SubjectView from '@/components/SubjectView';
import { getFileThumbnail, isImageFile } from '@/utils';

import { getContractExtraFiles, getOrderItem, getWorksheetItem, queryRecommendProductions } from '../services';

import './style.css';

import type { SubjectViewAction } from '@/components/SubjectView';
import type { ProcessViewAction } from '@/components/ProcessView';
import type { ImagePreviewAction } from '@/components/ImagePreview';
const fileType: Record<string, string> = {
  LETTER_AUTHORIZATION: '委托书',
  BUSINESS_LICENSE: '营业执照',
  AAA_CONFIRM: '盖章确认单',
};

const Container = () => {
  const [worksheet, setWorksheet] = useState<Record<string, any>>();
  const [recommends, setRecommends] = useState<Record<string, any>[]>([]);
  const [order, setOrder] = useState<Record<string, any>>();
  const [extraFiles, setExtraFiles] = useState<Record<string, any>[]>([]);
  const [shipProgress, setShipProgress] = useState<Record<string, any>>();
  // const [shipProgresses, setShipProgresses] = useState<Record<string, any>[]>([])
  const [loading, setLoading] = useState(true);

  const subjectViewRef = React.useRef<SubjectViewAction>();
  const processViewRef = React.useRef<ProcessViewAction>();
  const shipProcessViewRef = React.useRef<ShipProcessViewAction>();
  // const contractViewRef = React.useRef<ContractViewAction>();
  const imagePreviewRef = React.useRef<ImagePreviewAction>();
  const params = useParams();
  const navigate = useNavigate();

  const loadWorksheetDetail = async (worksheetId: string) => {
    const res = await getWorksheetItem(worksheetId);
    if (res?.code === 200) {
      setWorksheet(res.data);
      if (res.data?.ship_info) {
        try {
          const progress = JSON.parse(res.data.ship_info);
          setShipProgress(progress?.[0]);
          // setShipProgresses(progress?.[0]?.)
        } catch (error) {
          console.log(error);
        }
      }
      if (res.data?.order?.sn) {
        const result = await getOrderItem(res.data?.order?.sn);
        if (!result?.code) {
          setOrder(result);
        }
      }
    }
  };

  const loadContractExtraFiles = async (order_sn: string) => {
    const response = await getContractExtraFiles({ rel_id: order_sn });
    if (response.code === 200) {
      setExtraFiles(response.data);
    }
  };

  const loadRecommendProductions = async () => {
    const result = await queryRecommendProductions();
    if (result?.code === 200) {
      setRecommends(result.data?.data || []);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        setLoading(true);
        await Promise.all([
          loadWorksheetDetail(params.id),
          loadRecommendProductions(),
          loadContractExtraFiles(params.id),
        ]);
        setLoading(false);
      }
    })();
  }, [params]);

  // console.log(worksheet);

  return (
    <div className="worksheet">
      <NavBar
        onBack={() => {
          navigate(-1);
        }}
      >
        工单详情
      </NavBar>
      <div className="content">
        {loading || !worksheet ? (
          <div style={{ minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SpinLoading color="#007AFF" style={{ fontSize: 18 }} />
          </div>
        ) : (
          <div className="worksheet-content">
            <Section>
              <div className="status-wrap">
                <div className="status">
                  <CalendarOutline fontSize={24} style={{ color: '#333' }} />
                  <span className="status-text">工单进度</span>
                </div>
                <div
                  className="extra"
                  onClick={() => {
                    processViewRef.current?.open(worksheet.node_record);
                  }}
                >
                  <span className="extra-text">{worksheet.node_record?.[0]?.member_node_name}</span>
                  <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#007bff' }} />
                </div>
              </div>
            </Section>
            {/* <SubjectSection subject={worksheet.address} /> */}
            <Section>
              <ListCell title="工单编号" extra={worksheet.work_order_id} copyable extraPrimary />
              {/* <ListCell title="订单编号" extra={worksheet.order?.sn} chevron="right" /> */}
              <ListCell
                title="服务主体"
                extra={worksheet.case_main_name}
                chevron="right"
                onClick={() => {
                  subjectViewRef.current?.open(order?.order_main);
                }}
              />
              <ListCell
                title="服务商品"
                chevron="right"
                extra={<span dangerouslySetInnerHTML={{ __html: worksheet.service_goods_name }} />}
                onClick={() => {
                  window.location.href = `/goods-module/goods?goods_id=${worksheet.goods_info?.goods_id}&referrer=${window.location.pathname}`;
                }}
              />
              <ListCell title="商务顾问" extra={worksheet.follow_name} />
              {shipProgress && <ListCell title="快递单号" extra={shipProgress.mail_no} copyable />}
              {worksheet.work_auth?.ship_addr && <ListCell title="邮寄地址" extra={worksheet.work_auth?.ship_addr} />}
              {shipProgress && (
                <ListCell
                  title="快递进度"
                  chevron="right"
                  extra={shipProgress?.logistics_status_desc}
                  onClick={() => {
                    shipProcessViewRef.current?.open(shipProgress?.logistics_trace_detail_list);
                  }}
                />
              )}

              {/* <ListCell title="下单时间" extra={moment(worksheet.create_time * 1000).format('YYYY-MM-DD HH:mm:ss')} />
            <ListCell title="支付时间" extra={moment(worksheet.payment_time * 1000).format('YYYY-MM-DD HH:mm:ss')} />
            <ListCell title="商品总额" extra={`￥${worksheet.goods_original_total_price}`} extraPrimary /> */}
              {/* <ListCell title="支付方式" extra={paymentType[worksheet.payment_type]} /> */}
            </Section>
            {extraFiles?.length > 0 && (
              <Section title="合同附件">
                {extraFiles.map((file) => (
                  <ListCell
                    key={file.id}
                    title={file.tag ? fileType[file.tag] : file.file_name}
                    extra={file.file_name}
                    chevron="right"
                    onClick={() => {
                      imagePreviewRef.current?.open(file.file_path);
                    }}
                  />
                ))}
              </Section>
            )}
            {worksheet.answer_files?.length > 0 && (
              <Section title="官文材料">
                <div style={{ padding: 12 }}>
                  <Space wrap block>
                    {worksheet.answer_files?.map((item: Record<string, any>) => (
                      <Image
                        key={item.id}
                        src={getFileThumbnail(item.file_path)}
                        width={64}
                        height={64}
                        fit="cover"
                        style={{ borderRadius: 4, backgroundColor: '#f2f2f2' }}
                        onClick={() => {
                          if (isImageFile(item.file_path)) {
                            const images = worksheet.answer_files
                              .filter((el: any) => isImageFile(el.file_path))
                              .map((el: any) => el.file_path);
                            const idx = images.findIndex((el: any) => el === item.file_path);
                            imagePreviewRef.current?.open(images, idx);
                          } else {
                            window.open(item.file_path, '__blank');
                          }
                        }}
                      />
                    ))}
                  </Space>
                </div>
              </Section>
            )}

            <Section>{recommends && <RecommendSection products={recommends} />}</Section>
            {/* <Section title="商品信息">
            <WorksheetProduct products={worksheet.worksheet_sku_list} />
          </Section> */}
            {/* <Section title="合同信息">
            {order?.cont_preview_vos?.map((contract: Record<string, any>) => (
              <ListCell
                key={contract.con_no}
                title={contract.title}
                chevron="right"
                onClick={() => {
                  contractViewRef.current?.open(contract);
                }}
              />
            ))}
          </Section> */}
            {/* <Section>
            <WorksheetProductCell worksheetProducts={worksheet.products} onClick={() => {}} />
          </Section> */}
            {/* <div className="footer">
            <div className="footer-box">
              <div>
                <Button className="btn-outline" onClick={() => {}}>
                  确认订单
                </Button>
              </div>
            </div>
          </div> */}
          </div>
        )}
      </div>
      <SubjectView actionRef={subjectViewRef} />
      {/* <ContractView actionRef={contractViewRef} /> */}
      <ProcessView actionRef={processViewRef} />
      <ShipProcessView actionRef={shipProcessViewRef} />
      <ImagePreview multiple actionRef={imagePreviewRef} />
    </div>
  );
};

export default Container;
