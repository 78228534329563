import { Popup, Steps } from 'antd-mobile';
import moment from 'moment';
import React from 'react';

import './style.scss';

export interface ShipProcessViewAction {
  open: (records: Record<string, any>[]) => void;
}

export interface ComponentProps {
  actionRef?: React.MutableRefObject<ShipProcessViewAction | undefined>;
}

const ProcessView: React.FC<ComponentProps> = ({ actionRef }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [processes, setProcesses] = React.useState<Record<string, any>[]>([]);

  React.useImperativeHandle(actionRef, () => ({
    open: (records: Record<string, any>[]) => {
      setVisible(true);
      setProcesses(records);
    },
  }));
  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      bodyStyle={{ height: '75vh', overflowY: 'scroll', paddingBottom: 20  }}
    >
      <div className="node-process-content">
        <Steps direction="vertical">
          {processes.map((item) => (
            <Steps.Step
              key={item.id}
              status="finish"
              title={item.desc}
              description={moment(item.time).format('YYYY-MM-DD HH:mm:ss')}
            />
          ))}
        </Steps>
      </div>
    </Popup>
  );
};

export default React.memo(ProcessView);
