import { buyerapi, deliveryapi, intelligentapi } from '@/utils/request';

export const getUserOrderList = ({ order_status }: { order_status: string }) => {
  return buyerapi.get('trade/orders/wap/list', { order_status });
};

export const removeOrderItem = (id: string) => {
  return buyerapi.get(`trade/orders/${id}`, undefined, { handleCatch: true });
};

export const getOrderItem = (id: string) => {
  return buyerapi.get(`trade/orders/${id}`, undefined, { handleCatch: true });
};

export const getWorksheetItem = (id: string) => {
  return deliveryapi.get(`member/work/default/${id}`);
};

export const queryRecommendProductions = () => {
  return intelligentapi.get(`rec/rule/goodsPageByRuleId`, { pageSize: 100, pageNo: 1 });
};

export const getWorksheetProgreess = (id: string) => {
  return deliveryapi.get(`member/work/default/order/${id}`);
};

export const getContractExtraFiles = (params: { rel_id: string }) => {
  return buyerapi.get(`trade/orders/file/list`, params);
};

export const cancelOrderItem = ({ order_sn, reson }: { order_sn: string; reson: string | number }) => {
  return buyerapi.post(`trade/orders/${order_sn}/cancel`, { reson }, { handleCatchOriginal: true, notify: false });
};

export const getOrderContracts = (order_sn: string) => {
  return buyerapi.get(`buyer/agreement/order/${order_sn}`);
};
