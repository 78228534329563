import { Popup } from 'antd-mobile';
import React from 'react';
import './style.scss';

export interface ContractViewAction {
  open: (record: Record<string, any>) => void;
}

export interface ComponentProps {
  actionRef?: React.MutableRefObject<ContractViewAction | undefined>;
}

const Component: React.FC<ComponentProps> = ({ actionRef }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [contract, setContract] = React.useState<Record<string, any>>();

  React.useImperativeHandle(actionRef, () => ({
    open: (record: Record<string, any>) => {
      setVisible(true);
      setContract(record);
    },
  }));

  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        maxHeight: '88vh',
      }}
    >
      <div style={{ padding: '10px 0' }}>
        <div className={`content popup`}>
          {contract?.current_file?.images?.map((img: string) => (
            <img src={img} key={img} alt={img} />
          ))}
        </div>
      </div>
    </Popup>
  );
};

export default Component;
