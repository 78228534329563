
export enum OrderStatus {
  ALL = 'ALL',
  // 待付款
  WAIT_PAY = 'WAIT_PAY',
  // 待发货，发货之前可以取消订单
  DOING = 'DOING',
  // 待收货
  SHIPING = 'SHIPING',
  // 订单完成时进入订单维护状态，在订单维护时间内允许发起取消、退款等操作。
  COMPLETE = 'COMPLETE',
  // 用户手动取消、申请取消、过期自动取消均属于取消
  CANCELED = 'CANCELED',
}


export enum OrderStatusEnum {
  NEW = 'NEW',
  INTODB_ERROR = 'INTODB_ERROR',
  BALANCE_ERROR = 'BALANCE_ERROR',
  CONFIRM = 'CONFIRM',
  PAID_OFF = 'PAID_OFF',
  PAID_W_CONFIRM = 'PAID_W_CONFIRM',
  FORMED = 'FORMED',
  SHIPPED = 'SHIPPED',
  ROG = 'ROG',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  PAY_PARTIAL = 'PAY_PARTIAL',
  AFTER_SERVICE = 'AFTER_SERVICE',
}


// export const OrderStatusDesc = ['待付款', '待发货', '待收货', '已完成', '已取消', '已关闭'];
export const OrderStatusDesc: Record<string, any> = {
  ALL: '全部',
  UNPAY: '待付款',
  UNSHIP: '待发货',
  SHIPING: '已完成',
  COMPLETE: '已取消',
  CANCELED: '已关闭',
};

// export const OrderStatusIcons = ['clock', 'shopping-bag', 'shopping-bag', 'check-circle', 'close-circle'];
export const OrderStatusIcons = {
  UNPAY: 'clock',
  UNSHIP: 'shopping-bag',
  SHIPING: 'shopping-bag',
  COMPLETE: 'check-circle',
  CANCELED: 'close-circle',
};