import { Avatar, Badge, PullToRefresh } from 'antd-mobile';
import {
  CalendarOutline,
  FileOutline,
  LocationOutline,
  MessageOutline,
  SetOutline,
} from 'antd-mobile-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListCell from '@/components/ListCell';
import { StorageKey, TabKey } from '@/configs/enum';
import CurrentUserContext from '@/contexts/CurrentUserContext';
import BasicLayout from '@/layouts/basic';
import { isWechatEnvironment, wechatAuthentication } from '@/utils';
import { CarryOutOutlined, CustomerServiceOutlined, InteractionOutlined, WalletOutlined } from '@ant-design/icons';

import MemberSection from './MemberSection';
import { queryStatistics } from './services';

import './style.scss';

const MemberContainer = () => {
  // const [member, setMember] = useState<Record<string, any>>();
  const [statistics, setStatistics] = useState<Record<string, any>>();
  const { currentUser } = React.useContext(CurrentUserContext);
  const navigate = useNavigate();

  const checkLoginAndNavigate = React.useCallback(
    async (path: string, original = false) => {
      if (!currentUser) {
        navigate('/login?redirect=' + window.location.pathname);
      } else {
        original ? navigate(path) : (window.location.href = path);
      }
    },
    [currentUser, navigate]
  );

  const loadStatistics = async () => {
    const result = await queryStatistics();
    if (result?.code === 200) {
      setStatistics(result.data);
    }
  };

  // const updateCurrentUser = async () => {
  //   const result = await queryUserInfo();
  //   if (result) {
  //     if (!result.code) {
  //       setMember(result);
  //       localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(result));
  //     }
  //     if (result.code === 703) {
  //       // TOKEN无效，重新授权登录
  //       localStorage.removeItem(StorageKey.ACCESS_TOKEN);
  //       localStorage.removeItem(StorageKey.REFRESH_TOKEN);
  //       if (isWechatEnvironment()) {
  //         wechatAuthentication(window.location.href);
  //       }
  //     }
  //     // 如果用户未绑定手机号
  //     // if (!result.mobile && !ALLOW_PATHS.includes(window.location.pathname)) {
  //     //   window.location.href = '/member/bind-phone?redirect=' + window.location.href;
  //     // }
  //   }
  // };

  React.useEffect(() => {
    // 如果未登录，且环境是公众号
    const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
    if (!accessToken && isWechatEnvironment()) {
      wechatAuthentication(window.location.href);
    }
    if (accessToken) {
      loadStatistics();
    }
  }, []);

  // React.useEffect(() => {
  //   const user = localStorage.getItem(StorageKey.CURRENT_USER);
  //   if (user) {
  //     setMember(JSON.parse(user));
  //     loadStatistics();
  //   }
  // }, []);

  return (
    <BasicLayout current={TabKey.MEMBER} hideNavibar>
      <PullToRefresh
        onRefresh={async () => {
          await loadStatistics();
        }}
      >
        <div className="member-content">
          <div className="member-header">
            <div className="header-content" onClick={() => checkLoginAndNavigate('/mine-module/profile')}>
              <Avatar
                className="avatar"
                alt="知淼淼用户"
                style={{ borderRadius: '50%', '--size': '50px' }}
                src={currentUser?.face || '/static/images/member/icon-noface.jpg'}
              />
              <div className="header-info">
                <strong className="nickname">{currentUser ? currentUser.nickname : '登录/注册'}</strong>
                <p>欢迎来到知淼淼平台</p>
              </div>
            </div>
            <div className="header-options">
              <SetOutline
                fontSize={24}
                style={{ color: '#fff', margin: '4px 6px' }}
                onClick={() => {
                  checkLoginAndNavigate('/mine-module/setting');
                }}
              />
              <MessageOutline
                fontSize={26}
                style={{ color: '#fff', margin: '4px 6px' }}
                onClick={() => {
                  checkLoginAndNavigate('/mine-module/website-message');
                }}
              />
              {/* <span style={{ margin: '4px 6px' }}>
                <Badge
                  content={Number(statistics?.unread_msg_count) > 99 ? '99' : Number(statistics?.unread_msg_count)}
                >
                  <MessageOutline
                    fontSize={26}
                    style={{ color: '#fff' }}
                    onClick={() => {
                      checkLoginAndNavigate('/mine-module/website-message');
                    }}
                  />
                </Badge>
              </span> */}
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <MemberSection
              headerNav
              title="我的订单"
              headerNavLabel="全部订单"
              handleNavPress={() => checkLoginAndNavigate('/order/list')}
            >
              <div className="utils-content">
                <div className="utils-item" onClick={() => checkLoginAndNavigate('/order/list?order_status=WAIT_PAY')}>
                  {Number(statistics?.wait_pay_count) > 0 ? (
                    <Badge content={Number(statistics?.wait_pay_count)}>
                      <WalletOutlined style={{ color: '#666', fontSize: 24 }} />
                    </Badge>
                  ) : (
                    <WalletOutlined style={{ color: '#666', fontSize: 24 }} />
                  )}
                  <span className="label">待付款</span>
                </div>
                <div className="utils-item" onClick={() => checkLoginAndNavigate('/order/list?order_status=DOING')}>
                  {Number(statistics?.doing_count) > 0 ? (
                    <Badge content={Number(statistics?.doing_count)}>
                      {/* <TruckOutline fontSize={26} style={{ color: '#666' }} /> */}
                      <InteractionOutlined style={{ color: '#666', fontSize: 24 }} />
                    </Badge>
                  ) : (
                    <InteractionOutlined style={{ color: '#666', fontSize: 24 }} />
                    // <TruckOutline fontSize={26} style={{ color: '#666' }} />
                  )}
                  <span className="label">服务中</span>
                </div>
                <div className="utils-item" onClick={() => checkLoginAndNavigate('/order/list?order_status=COMPLETE')}>
                  {Number(statistics?.completed_count) > 0 ? (
                    <Badge content={Number(statistics?.completed_count)}>
                      <CarryOutOutlined style={{ color: '#666', fontSize: 24 }} />
                    </Badge>
                  ) : (
                    <CarryOutOutlined style={{ color: '#666', fontSize: 24 }} />
                  )}
                  <span className="label">已完成</span>
                </div>
                <div
                  className="utils-item"
                  onClick={() =>
                    checkLoginAndNavigate(
                      `/pages/ticket/index?current=${currentUser && currentUser.ticket > 0 ? '1' : '0'}`
                    )
                  }
                >
                  <CustomerServiceOutlined style={{ color: '#666', fontSize: 24 }} />
                  <span className="label">售后/退换</span>
                </div>
              </div>
            </MemberSection>
            <MemberSection title="常用服务">
              <div style={{ padding: '0 8px' }}>
                <ListCell
                  title="我的工单"
                  icon={<CalendarOutline fontSize={24} style={{ color: '#00aaff' }} />}
                  chevron="right"
                  onClick={() => {
                    checkLoginAndNavigate(`/worksheet/list`);
                  }}
                />
                {/* <ListCell
                  title="我的收藏"
                  icon={<StarOutline fontSize={24} style={{ color: '#eb9a3b' }} />}
                  chevron="right"
                  onClick={() => {
                    checkLoginAndNavigate(`/mine-module/collect`);
                  }}
                /> */}
                <ListCell
                  title="我的发票"
                  icon={<FileOutline fontSize={24} style={{ color: '#9640ff' }} />}
                  chevron="right"
                  onClick={() => {
                    checkLoginAndNavigate(`/mine-module/my-receipt/receipt`);
                  }}
                />
                <ListCell
                  title="我的主体"
                  icon={<LocationOutline fontSize={24} style={{ color: '#00c6af' }} />}
                  chevron="right"
                  onClick={() => {
                    checkLoginAndNavigate(`/mine-module/InvoiceServe/InvoiceServe?key=true`);
                  }}
                />
                {/* <ListCell
                  title="我的合同"
                  icon={<EyeOutline fontSize={24} style={{ color: '#00aaff' }} />}
                  chevron="right"
                /> */}
                {/* <ListCell
                  title="购物车"
                  icon={<ShoppingCartOutlined style={{ color: '#4392f7', fontSize: 24 }} />}
                  chevron="right"
                  onClick={() => {
                    checkLoginAndNavigate(`/pages/tabs/cart/cart`);
                  }}
                /> */}
              </div>
            </MemberSection>
            {/* <MemberSection title="常用服务">
              <div className="utils-content">
                <div className="utils-item" onClick={() => checkLoginAndNavigate('/pages/address/index')}>
                  <StarOutline className="utils-icon icon-collection" fontSize={24} style={{ color: '#fff' }} />
                  <span className="label">我的收藏</span>
                </div>
                <div className="utils-item">
                  <FileOutline className="utils-icon icon-ticket" fontSize={24} style={{ color: '#fff' }} />
                  <span className="label">我的发票</span>
                </div>
                <div className="utils-item">
                  <EyeOutline className="utils-icon icon-footmark" fontSize={24} style={{ color: '#fff' }} />
                  <span className="label">我的合同</span>
                </div>
                <div className="utils-item">
                  <LocationOutline className="utils-icon icon-subject" fontSize={24} style={{ color: '#fff' }} />
                  <span className="label">我的主体</span>
                </div>
                <div className="utils-item">
                  <ShopbagOutline className="utils-icon icon-shopcart" fontSize={24} style={{ color: '#fff' }} />
                  <span className="label">购物车</span>
                </div>
              </div>
            </MemberSection> */}
          </div>
        </div>
      </PullToRefresh>
    </BasicLayout>
  );
};

export default MemberContainer;
