import React from 'react';

import type { User } from '@/types';

const CurrentUserContext = React.createContext<{
  currentUser?: User;
  setCurrentUser: (user: User) => void;
  updateCurrentUser?: () => void;
  clearCurrentUser?: () => void;
}>({ setCurrentUser: () => {} });

export default CurrentUserContext;
