export const envHosts: Record<string, Record<string, any>> = {
  // 开发环境
  development: {
    // base: 'http://192.168.40.91:7077/scbase',
    base: 'http://localhost:9000',
    buyer: 'http://192.168.40.91:7077/scbuyer',
    advert: 'http://192.168.40.91:7077/ipmanbase',
    delivery: 'http://192.168.40.91:7077/sp',
    intelligent: 'http://192.168.40.91:7077/intelligent',
    wechat: 'https://open.weixin.qq.com',
  },
  // 测试环境
  staging: {
    base: 'http://192.168.40.205:7077/scbase',
    buyer: 'http://192.168.40.205:7077/scbuyer',
    advert: 'http://192.168.40.205:7077/ipmanbase',
    delivery: 'http://192.168.40.205:7077/sp',
    intelligent: 'http://192.168.40.205:7077/intelligent',
    wechat: 'https://open.weixin.qq.com',
  },
  // 生产环境
  production: {
    base: 'https://openapi.imipman.com/scbase',
    buyer: 'https://openapi.imipman.com/scbuyer',
    advert: 'https://openapi.imipman.com/ipmanbase',
    delivery: 'https://openapi.imipman.com/sp',
    intelligent: 'https://openapi.imipman.com/intelligent',
    wechat: 'https://open.weixin.qq.com',
  },
};
