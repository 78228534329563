import { ImageViewer } from 'antd-mobile';
import React from 'react';

export interface ImagePreviewAction {
  open: (images: string[], curr?: number) => void;
}

export interface ComponentProps {
  multiple?: boolean;
  actionRef?: React.MutableRefObject<ImagePreviewAction | undefined>;
}

const PreviewComponent: React.FC<ComponentProps> = ({ multiple = false, actionRef }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [current, setCurrent] = React.useState<number>(0);
  const [images, setImages] = React.useState<string[]>([]);

  React.useImperativeHandle(actionRef, () => ({
    open: (records: string[], curr = 0) => {
      setVisible(true);
      setImages(records);
      setCurrent(curr);
    },
  }));
  if (!visible) {
    return null;
  }
  return multiple ? (
    <ImageViewer.Multi
      images={images}
      visible={visible}
      defaultIndex={current}
      onClose={() => {
        setVisible(false);
      }}
    />
  ) : (
    <ImageViewer
      image={images?.[0]}
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
    />
  );
};

export default React.memo(PreviewComponent);
