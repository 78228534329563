import { Button, NavBar, Picker, SpinLoading, Toast } from 'antd-mobile';
import { ClockCircleOutline } from 'antd-mobile-icons';
import { PickerValue } from 'antd-mobile/es/components/picker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImagePreview, { ImagePreviewAction } from '@/components/ImagePreview';
import ListCell from '@/components/ListCell';
import Section from '@/components/Section';
import SubjectView from '@/components/SubjectView';

import ContractView from '../components/ContractView';
import OrderProduct from '../components/OrderProduct';
import OrderWorksheet from '../components/OrderWorksheet';
import { cancelOrderItem, getContractExtraFiles, getOrderContracts, getOrderItem, getWorksheetProgreess } from '../services';

import './style.scss';

import type { SubjectViewAction } from '@/components/SubjectView';
import type { ContractViewAction } from '../components/ContractView';
const fileType: Record<string, string> = {
  LETTER_AUTHORIZATION: '委托书',
  BUSINESS_LICENSE: '营业执照',
  AAA_CONFIRM: '盖章确认单',
};

const handlePaynow = (row: Record<string, any>) => {
  if (row.pay_method === 1) {
    if (row.pay_bill?.trade_record_id) {
      window.location.href = `/order-module/cashier/cashier?trade_sn=${row.trade_sn}&trade=true&trade_recode=${row.pay_bill.trade_record_id}`;
    } else {
      window.location.href = `/order-module/order/staging-detail?orderSn=${row.sn}&trade_sn=${row.trade_sn}`;
    }
  } else {
    if (row.pay_bill?.trade_record_id) {
      window.location.href = `/order-module/cashier/cashier?trade_sn=${row.trade_sn}&trade=true&trade_recode=${row.pay_bill.trade_record_id}`;
    }
  }
};

// const cancelResonList = [
//   {
//     name: '买多了',
//     disabled: false,
//   },
//   {
//     name: '买错商品了',
//     disabled: false,
//   },
//   {
//     name: '联系人信息有误',
//     disabled: false,
//   },
//   {
//     name: '没用优惠券',
//     disabled: false,
//   },
//   {
//     name: '不想要了',
//     disabled: false,
//   },
//   {
//     name: '其它',
//     disabled: false,
//   },
// ];

const Container = () => {
  const [order, setOrder] = useState<Record<string, any>>();
  const [loading, setLoading] = useState(true);
  const [extraFiles, setExtraFiles] = useState<Record<string, any>[]>([]);
  const subjectViewRef = React.useRef<SubjectViewAction>();
  const contractViewRef = React.useRef<ContractViewAction>();
  const [worksheets, setWorksheets] = useState<Record<string, any>[]>([]);
  const imagePreviewRef = React.useRef<ImagePreviewAction>();
  const [resonVisible, setResonVisible] = useState<boolean>(false);
  const [currentReson, setCurrentReson] = useState<PickerValue[]>([]);
  const [contracts, setContracts] = useState<Record<string, any>[]>([])
  const params = useParams();
  const navigate = useNavigate();

  const loadWorksheetProcess = async (idx: string) => {
    const result = await getWorksheetProgreess(idx);
    if (result?.code === 200) {
      setWorksheets(result.data || []);
    }
  };

  const loadContractExtraFiles = async (order_sn: string) => {
    const response = await getContractExtraFiles({ rel_id: order_sn });
    if (response.code === 200) {
      setExtraFiles(response.data);
    }
  };

  const loadOrderContracts = async (order_sn: string) => {
    const response = await getOrderContracts(order_sn);
    if (response.code === 200) {
      setContracts(response.data || []);
    }
  };

  const loadOrderDetail = async (orderId: string) => {
    const res = await getOrderItem(orderId);
    if (!res.code) {
      setOrder(res);
      // if (res.data.work_ticket) {
      //   setTicketNodes(res.data.work_ticket[0].nodes.reverse());
      // }
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        setLoading(true);
        await Promise.all([
          loadOrderDetail(params.id),
          loadWorksheetProcess(params.id),
          loadContractExtraFiles(params.id),
          loadOrderContracts(params.id),
        ]);
        setLoading(false);
      }
    })();
  }, [params]);

  // useDidShow(async () => {
  //   const reloadOrderDetail = Taro.getStorageSync('reloadOrderDetail');
  //   if (reloadOrderDetail) {
  //     const { orderId } = router.params;
  //     if (orderId) {
  //       Taro.showLoading({ title: '加载中' });
  //       // 如果是待付款及已取消，请求订单表。否则请求子订单表
  //       await loadOrderDetail(orderId);
  //       Taro.removeStorageSync('reloadOrderDetail');
  //       Taro.hideLoading();
  //     }
  //   }
  // });

  return (
    <div className="orderdetail">
      <NavBar
        onBack={() => {
          navigate(-1);
        }}
      >
        订单详情
      </NavBar>
      <div className="content">
        {loading || !order ? (
          <div style={{ minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SpinLoading color="#007AFF" style={{ fontSize: 18 }} />
          </div>
        ) : (
          <div className="orderdetail-content">
            <Section>
              <div className="status-wrap">
                <div className="status">
                  <ClockCircleOutline fontSize={26} style={{ color: '#007bff' }} />
                  <span className="status-text">{order.order_status_text}</span>
                </div>
                {/* <div className="extra" onClick={() => {}}>
                <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#999' }} />
              </div> */}
              </div>
            </Section>
            {/* <SubjectSection subject={order.address} /> */}
            <Section>
              <ListCell title="订单编号" extra={order.sn} copyable extraPrimary />
              <ListCell
                title="合同主体"
                extra={order.order_main?.name}
                chevron="right"
                onClick={() => {
                  subjectViewRef.current?.open(order.order_main);
                }}
              />
              <ListCell title="下单时间" extra={moment(order.create_time * 1000).format('YYYY-MM-DD HH:mm:ss')} />
              <ListCell title="支付时间" extra={moment(order.payment_time * 1000).format('YYYY-MM-DD HH:mm:ss')} />
              {order.pay_method === 1 && (
                <ListCell
                  title="付款方式"
                  extra={order.pay_method === 1 ? '分期付款' : '一次性付款'}
                  chevron="right"
                  onClick={() => {
                    window.location.href = `/order-module/order/staging-detail?orderSn=${order.sn}&trade_sn=${order.trade_sn}`;
                  }}
                />
              )}
              <ListCell title="商品总额" extra={`￥${order.goods_original_total_price}`} extraPrimary />
              {/* <ListCell title="支付方式" extra={paymentType[order.payment_type]} /> */}
            </Section>
            {extraFiles?.length > 0 && (
              <Section title="合同附件" headerBorder>
                {extraFiles.map((file) => (
                  <ListCell
                    key={file.id}
                    title={file.tag ? fileType[file.tag] : file.file_name}
                    extra={file.file_name}
                    chevron="right"
                    onClick={() => {
                      imagePreviewRef.current?.open([file.file_path]);
                    }}
                  />
                ))}
              </Section>
            )}
            {/* <Section>
            <ListCell
              title="合同主体"
              extra={order.order_main?.name}
              chevron="right"
              onClick={() => {
                subjectViewRef.current?.open(order.order_main);
              }}
            />
          </Section> */}
            <Section title="商品信息" headerBorder>
              <OrderProduct products={order.order_sku_list} />
            </Section>
            {worksheets?.length > 0 && (
              <Section title="工单信息" headerBorder>
                <OrderWorksheet worksheets={worksheets} />
              </Section>
            )}
            {contracts.length > 0 && (
              <Section title="合同信息" headerBorder>
                <div style={{ padding: 5 }}>
                  {contracts?.map((contract: Record<string, any>) => (
                    <ListCell
                      key={contract.agreement_no}
                      title={contract.name}
                      chevron="right"
                      onClick={() => {
                        contractViewRef.current?.open(contract);
                      }}
                    />
                  ))}
                </div>
              </Section>
            )}
            {/* <Section>
            <OrderProductCell orderProducts={order.products} onClick={() => {}} />
          </Section> */}
            {(order.order_operate_allowable_vo?.allow_cancel || order.order_operate_allowable_vo?.allow_pay) && (
              <div className="footer">
                <div className="footer-box">
                  {/* {order.order_operate_allowable_vo?.allow_receipt && (
                  <Button className="btn" color="primary" fill="outline" onClick={() => {}}>
                    申请发票
                  </Button>
                )} */}
                  {order.order_operate_allowable_vo?.allow_cancel && (
                    <Button
                      className="btn-cancel"
                      onClick={() => {
                        setResonVisible(true);
                      }}
                    >
                      取消订单
                    </Button>
                  )}
                  {order.order_operate_allowable_vo?.allow_pay && (
                    <Button className="btn-pay" onClick={() => handlePaynow(order)}>
                      立即支付
                    </Button>
                  )}
                  {/* <Button className="btn-outline" onClick={() => {}}>
                  确认订单
                </Button> */}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <SubjectView actionRef={subjectViewRef} />
      <ContractView actionRef={contractViewRef} />
      <ImagePreview multiple={false} actionRef={imagePreviewRef} />
      <Picker
        columns={[
          [
            {
              label: '买多了',
              value: '买多了',
            },
            {
              label: '买错商品了',
              value: '买错商品了',
            },
            {
              label: '联系人信息有误',
              value: '联系人信息有误',
            },
            {
              label: '没用优惠',
              value: '没用优惠',
            },
            {
              label: '不想要了',
              value: '不想要了',
            },
            {
              label: '其它',
              value: '其它',
            },
          ],
        ]}
        visible={resonVisible}
        onClose={() => {
          setResonVisible(false);
          setCurrentReson([]);
        }}
        value={currentReson}
        onConfirm={async (val) => {
          console.log(val);
          setCurrentReson(val);
          if (val.length && val[0] && params.id) {
            await cancelOrderItem({ order_sn: params.id, reson: val[0] });
            Toast.show({ content: '订单取消成功' });
            loadOrderDetail(params.id);
          }
        }}
      />
      {/* <ProcessView data={ticketNodes} visible={processViewVisible} handleClose={() => setProcessViewVisible(false)} /> */}
    </div>
  );
};

export default Container;
