import { RightOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';

// import { Amount } from '@/components/Utils';
// import { getVariantName } from '@/utils';

import './style.scss';

export interface ComponentProps {
  worksheets?: Record<string, any>[];
}

const Component = ({ worksheets }: ComponentProps) => {
  const navigate = useNavigate();

  return (
    <div className="order-worksheet-box">
      {worksheets?.map((worksheet: Record<string, any>, idx: number) => (
        <div
          key={worksheet.work_order_id}
          className={`worksheet-cell ${idx === 0 ? '' : 'border'}`}
          onClick={() => {
            navigate('/worksheet/detail/' + worksheet.work_order_id);
          }}
        >
          <div className="cell-left">
            <span className="cell-title" dangerouslySetInnerHTML={{ __html: worksheet.goods_name }} />
            {worksheet?.node_desc && <span className="cell-desc">{worksheet.node_desc}</span>}
          </div>
          <div className="cell-right">
            <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#999', paddingTop: 2 }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Component;
