// 手机号
export const MOBILE_REG = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;

// 电子邮箱
export const EMAIL_REG = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// 密码【6-20位】
export const PASSWORD_REG = /^[@A-Za-z0-9!#$%^&*.~,]{6,20}$/;

export const MP_APPID = 'wx106c2d9ccfc5bb01';
// export const MP_APPID = 'wxb4b198f4dac903da'

export const MP_LOGIN_CALLBACK = 'http://m.imipman.com/member/wechat/callback';

export const WECHAT_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize';

export const ALLOW_PATHS = ['/login', '/member/bind-phone', 'member/wechat/callback'];
