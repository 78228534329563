import ListCell from '@/components/ListCell';
import Section from '@/components/Section';
import { Popup } from 'antd-mobile';
import React from 'react';

export interface SubjectViewAction {
  open: (record: Record<string, any>) => void;
}

export interface ComponentProps {
  actionRef?: React.MutableRefObject<SubjectViewAction | undefined>;
}

const Component: React.FC<ComponentProps> = ({ actionRef }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [subject, setSubject] = React.useState<Record<string, any>>();

  React.useImperativeHandle(actionRef, () => ({
    open: (record: Record<string, any>) => {
      setVisible(true);
      setSubject(record);
    },
  }));

  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        maxHeight: '70vh',
      }}
    >
      <div style={{ padding: 10, overflowY: 'scroll', maxHeight: '70vh' }}>
        {subject && (
          <Section>
            <ListCell title="主体名称" extra={subject?.name} extraPrimary copyable />
            <ListCell title="主体类型" extra={subject?.type === 'PERSONAL' ? '个人' : '企业'} />
            <ListCell title={subject?.type === 'PERSONAL' ? '身份证号' : '统一社会信用代码'} extra={subject.id_num} />
            <ListCell title="联系人" extra={subject.contact_name} />
            <ListCell title="联系人电话" extra={subject.contact_mobile} />
            <ListCell title="地址" extra={subject.address_info} />
            <ListCell title="邮箱" extra={subject.contact_email} />
          </Section>
        )}
      </div>
    </Popup>
  );
};

export default Component;
