import { buyerapi, deliveryapi, intelligentapi } from '@/utils/request';

export const getUserWorksheetList = () => {
  return deliveryapi.get('member/work/default/list');
};

export const getOrderItem = (id: string) => {
  return buyerapi.get(`trade/orders/${id}`, undefined, { handleCatch: true });
};

export const getWorksheetItem = (id: string) => {
  return deliveryapi.get(`member/work/default/${id}`);
};

export const queryRecommendProductions = () => {
  return intelligentapi.get(`rec/rule/goodsPageByRuleId`, { pageSize: 100, pageNo: 1 });
};

export const getWorksheetProgreess = (id: string) => {
  return deliveryapi.get(`member/work/default/order/${id}`);
};

export const getContractExtraFiles = (params: { rel_id: string }) => {
  return buyerapi.get(`trade/orders/file/list`, params);
};
