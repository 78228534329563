import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import { ALLOW_PATHS } from './configs/constant';
import { StorageKey } from './configs/enum';
import CurrentUserContext from './contexts/CurrentUserContext';
import Authorization from './pages/account/Authorization';
import BindPhone from './pages/account/BindPhone';
import Login from './pages/account/Login';
import Member from './pages/account/Member';
import Activity from './pages/Activity';
import Agreement from './pages/Agreement';
import Confirm from './pages/Confirm';
import Home from './pages/Home';
import OrderDetail from './pages/order/OrderDetail';
import OrderList from './pages/order/OrderList';
import Worksheet from './pages/worksheet/Worksheet';
import WorksheetList from './pages/worksheet/WorksheetList';
import { queryUserInfo } from './services/common';
import { User } from './types';
import { isWechatEnvironment, wechatAuthentication } from './utils';

import './App.css';

const App = () => {
  const [currentUser, setCurrentUser] = React.useState<User>();

  const updateCurrentUser = async () => {
    const result = await queryUserInfo();
    if (result) {
      if (!result.code) {
        setCurrentUser(result);
        localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(result));
      }
      if (result.code === 703) {
        // TOKEN无效，重新授权登录
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
        localStorage.removeItem(StorageKey.REFRESH_TOKEN);
        // 如果环境是公众号
        if (isWechatEnvironment()) {
          wechatAuthentication(window.location.href);
        }
      }
      // 如果用户未绑定手机号
      // if (!result.mobile && !ALLOW_PATHS.includes(window.location.pathname)) {
      //   window.location.href = '/member/bind-phone?redirect=' + window.location.href;
      // }
    }
  };

  React.useEffect(() => {
    const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
    // if (!accessToken && isWechatEnvironment()) {
    //   wechatAuthentication(window.location.href);
    // }
    if (accessToken) {
      updateCurrentUser();
    }
  }, []);

  return (
    <div className="App">
      <CurrentUserContext.Provider
        value={{
          currentUser,
          updateCurrentUser,
          setCurrentUser: (user: User) => {
            setCurrentUser(user);
          },
          clearCurrentUser: () => {
            setCurrentUser(undefined);
          },
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="activity" element={<Activity />} />
          <Route path="order/list" element={<OrderList />} />
          <Route path="order/detail/:id" element={<OrderDetail />} />
          <Route path="worksheet/list" element={<WorksheetList />} />
          <Route path="worksheet/detail/:id" element={<Worksheet />} />
          <Route path="confirm" element={<Confirm />} />
          <Route path="agreement" element={<Agreement />} />
          <Route path="login" element={<Login />} />
          <Route path="member/center" element={<Member />} />
          <Route path="member/wechat/callback" element={<Authorization />} />
          <Route path="member/bind-phone" element={<BindPhone />} />
        </Routes>
      </CurrentUserContext.Provider>
    </div>
  );
};

export default App;
