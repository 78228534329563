import { Button, Input, Toast } from 'antd-mobile';
import { DownOutline, MoreOutline, RightOutline } from 'antd-mobile-icons';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Checkbox from '../Checkbox';

import './index.css';

type ListCellProp = {
  title: string;
  extra?: React.ReactNode;
  border?: boolean;
  chevron?: 'right' | 'down';
  icon?: React.ReactNode;
  extraIcon?: string;
  iconSize?: number;
  // switchable?: boolean;
  // switchChecked?: boolean;
  // onSwitchChange?: (ev: BaseEventOrig<{ value: boolean }>) => void;
  onClick?: () => void;
  checkable?: boolean;
  checked?: boolean;
  extraPrimary?: boolean;
  navigate?: string;
  disabled?: boolean;
  copyable?: boolean;
  viewmore?: boolean;
  size?: 'normal' | 'small';
  mode?: 'normal' | 'switch' | 'check' | 'input' | 'dropdown' | 'navigate' | 'quantity' | 'picker';
};

const ListCell: React.FC<ListCellProp> = ({
  title,
  icon,
  // iconSize = 30,
  chevron,
  extra,
  border,
  // onSwitchChange,
  // switchChecked,
  // switchable,
  onClick,
  checkable,
  checked,
  extraIcon,
  extraPrimary,
  disabled,
  copyable,
  viewmore = false,
  size = 'normal',
}) => {
  return (
    <div className={`list-cell ${border && 'border'} ${size}`}>
      <div className={`list-cell-box ${disabled && 'disabled'}`} onClick={() => onClick?.()}>
        {checkable && (
          <div>
            <Checkbox disabled={disabled} checked={!!checked} />
          </div>
        )}
        {icon && <div className="left-icon">{icon}</div>}
        <div className="list-cell-content">
          <div className="list-cell-title">
            <span className="cell-title-text">{title}</span>
          </div>
          {(extra || chevron || extraIcon) && (
            <div className="extra">
              {extra && <span className={`extra-text ${extraPrimary && 'extra-primary'}`}>{extra}</span>}
              {/* {switchable && (
                <div className="right-icon">
                  <Switch onChange={onSwitchChange} checked={switchChecked} className="switch" color="#121212" />
                </div>
              )} */}
              {copyable && extra && typeof extra === 'string' && (
                <CopyToClipboard text={extra} onCopy={() => { Toast.show({content: '已拷贝至剪贴板', position: 'top'}) }}>
                  <Button size="small" color="primary" fill="none" style={{ fontSize: 12, padding: '3px 6px' }}>
                    拷贝
                  </Button>
                  {/* <MoreOutline style={{ fontSize: 16, color: '#999' }} /> */}
                </CopyToClipboard>
              )}
              {chevron && (
                <div className="right-icon">
                  {/* <AtIcon value={`chevron-${chevron}`} className={`arrow ${chevron}`} color="#999" size={16} /> */}
                  {chevron === 'right' ? (
                    <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#999' }} />
                  ) : (
                    <DownOutline className={`arrow down`} style={{ fontSize: 16, color: '#999' }} />
                  )}
                </div>
              )}
              {viewmore && (
                <div className="right-icon">
                  <MoreOutline className={`arrow right`} style={{ fontSize: 20, color: '#999' }} />
                </div>
              )}
              {extraIcon && (
                <div className="extra-icon">
                  {/* <AtIcon value={extraIcon} size={iconSize} /> */}
                  {extraIcon}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type InputCellProp = {
  title: string;
  extra?: React.ReactNode;
  border?: boolean;
  chevron?: 'right' | 'bottom';
  icon?: React.ReactNode;
  iconSize?: number;
  onClick?: () => void;
  type?: 'text' | 'number' | 'idcard' | 'digit';
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  viewmore?: boolean;
  onChange?: (val: string) => void;
};

const Component: React.FC<InputCellProp> = ({
  title,
  icon,
  chevron,
  extra,
  border,
  onClick,
  type = 'text',
  placeholder,
  value,
  disabled,
  viewmore = false,
  onChange,
}) => {
  return (
    <div className={`input-cell ${border && 'border'}`}>
      <div className="list-cell-box" onClick={() => onClick && onClick()}>
        {!!icon && <div className="left-icon">{icon}</div>}
        <div className="list-cell-content">
          <div className="input-cell-title">
            <span>{title}</span>
          </div>
          <div className="list-cell-input-box">
            <Input
              className="list-cell-input"
              type={type}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(val) => onChange?.(val)}
            />
          </div>
          {(extra || chevron) && (
            <div className="extra">
              {extra}
              {chevron && (
                <div className="right-icon">
                  {chevron === 'right' ? (
                    <RightOutline className={`arrow right`} style={{ fontSize: 16, color: '#999' }} />
                  ) : (
                    <DownOutline className={`arrow down`} style={{ fontSize: 16, color: '#999' }} />
                  )}
                </div>
              )}
              {viewmore && (
                <div className="right-icon">
                  <MoreOutline className={`arrow right`} style={{ fontSize: 16, color: '#007bff' }} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const InputCell = React.memo(Component);

export default React.memo(ListCell);
