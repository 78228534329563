import { Button, ErrorBlock, Picker, PullToRefresh, SpinLoading, Tabs, Toast } from 'antd-mobile';
import { PickerValue } from 'antd-mobile/es/components/picker';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Amount } from '@/components/Utils';
import { StorageKey, TabKey } from '@/configs/enum';
import BasicLayout from '@/layouts/basic';
import { isWechatEnvironment, wechatAuthentication } from '@/utils';

import { OrderStatus } from '../config';
import { cancelOrderItem, getUserOrderList } from '../services';

import './style.scss';

const Container = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Record<string, any>>([]);
  const [currentTab, setCurrentTab] = useState<OrderStatus>(OrderStatus.ALL);
  const [resonVisible, setResonVisible] = useState<boolean>(false);
  const [currentReson, setCurrentReson] = useState<PickerValue[]>([]);
  const [currentOrder, setCurrentOrder] = useState<Record<string, any>>();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const loadOrders = async ({ order_status = OrderStatus.ALL }) => {
    setLoading(true);
    const res = await getUserOrderList({ order_status });
    if (res?.code === 200) {
      const data = res.data.map((item: Record<string, any>) => ({
        ...item,
        order_sku_list: item.order_sku_list?.map((sku: Record<string, any>) => {
          let specValues = '';
          if (sku.spec_list !== null) {
            sku.spec_list.forEach((spec: Record<string, any>) => {
              specValues += spec.spec_value + ',';
            });
          }
          return { ...sku, variant: specValues.slice(0, specValues.length - 1) };
        }),
      }));
      setOrders(data);
    }
    setLoading(false);
  };

  const handlePaynow = (row: Record<string, any>) => {
    if (row.pay_method === 1) {
      if (row.pay_bill?.trade_record_id) {
        window.location.href = `/order-module/cashier/cashier?trade_sn=${row.trade_sn}&trade=true&trade_recode=${row.pay_bill.trade_record_id}`;
      } else {
        window.location.href = `/order-module/order/staging-detail?orderSn=${row.sn}&trade_sn=${row.trade_sn}`;
      }
    } else {
      if (row.pay_bill?.trade_record_id) {
        window.location.href = `/order-module/cashier/cashier?trade_sn=${row.trade_sn}&trade=true&trade_recode=${row.pay_bill.trade_record_id}`;
      }
    }
  };

  // React.useEffect(() => {
  //   loadOrders({ order_status: currentTab })
  // }, [currentTab]);

  // const updateCurrentUser = async () => {
  //   const result = await queryUserInfo();
  //   if (result) {
  //     if (!result.code) {
  //       localStorage.setItem(StorageKey.CURRENT_USER, JSON.stringify(result));
  //     }
  //     if (result.code === 703) {
  //       // TOKEN无效，重新授权登录
  //       localStorage.removeItem(StorageKey.ACCESS_TOKEN);
  //       localStorage.removeItem(StorageKey.REFRESH_TOKEN);
  //       if (isWechatEnvironment()) {
  //         wechatAuthentication(window.location.href);
  //       }
  //     }
  //     // 如果用户未绑定手机号
  //     // if (!result.mobile && !ALLOW_PATHS.includes(window.location.pathname)) {
  //     //   window.location.href = '/member/bind-phone?redirect=' + window.location.href;
  //     // }
  //   }
  // };

  // React.useEffect(() => {
  //   // 如果未登录，且环境是公众号
  //   const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
  //   if (!accessToken && isWechatEnvironment()) {
  //     wechatAuthentication(window.location.href);
  //   }
  //   if (accessToken) {
  //     updateCurrentUser();
  //   }
  // }, []);

  React.useEffect(() => {
    (async () => {
      const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
      if (!accessToken && isWechatEnvironment()) {
        wechatAuthentication(window.location.href);
        return;
      }
      // if (accessToken) {
      //   await updateCurrentUser();
      // }
      const status = searchParams.get('order_status');
      if (status && ['WAIT_PAY', 'DOING', 'COMPLETE'].includes(status)) {
        setCurrentTab(status as OrderStatus);
        loadOrders({ order_status: status as OrderStatus });
      } else {
        setCurrentTab(OrderStatus.ALL);
        loadOrders({ order_status: OrderStatus.ALL });
      }
    })();
  }, [searchParams]);

  return (
    <BasicLayout hideNavibar current={TabKey.ORDER}>
      <div className="order-container">
        <div className="order-tabs">
          <Tabs
            style={{ width: '100%' }}
            activeKey={currentTab}
            onChange={async (key) => {
              setSearchParams({ order_status: key });
            }}
          >
            <Tabs.Tab title="全部" key={OrderStatus.ALL} />
            <Tabs.Tab title="待付款" key={OrderStatus.WAIT_PAY} />
            <Tabs.Tab title="服务中" key={OrderStatus.DOING} />
            <Tabs.Tab title="已完成" key={OrderStatus.COMPLETE} />
          </Tabs>
        </div>

        <div className="content">
          <div className="order-list">
            <PullToRefresh
              onRefresh={async () => {
                loadOrders({ order_status: currentTab });
              }}
            >
              {loading ? (
                <div style={{ minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SpinLoading color="#007AFF" style={{ fontSize: 18 }} />
                </div>
              ) : (
                <div className="order-list-component">
                  {orders.length > 0 ? (
                    orders.map((item: Record<string, any>) => (
                      <div
                        className="order-item"
                        key={item.sn}
                        onClick={() => {
                          navigate('/order/detail/' + item.sn);
                        }}
                      >
                        <div className="header">
                          <span className="create-time">{item.sn}</span>
                          <div className="header-right">
                            <span className="status">{item.order_status_text}</span>
                            {/* <Button
                          className="remove-box"
                          onClick={() => {
                            Modal.confirm({
                              content: '确定要删除该订单吗',
                              onConfirm: async () => {
                                setLoading(true);
                                const res = await removeOrderItem(item.id);
                                if (res?.code === 200) {
                                  await loadOrders({ order_status: currentTab });
                                  setLoading(false);
                                }
                              },
                            });
                          }}
                        >
                          <DeleteOutline style={{ color: '#666' }} fontSize={12} />
                        </Button> */}
                            {/* {item.status >= OrderStatus.COMPLETE && (
                            <Button
                              className="remove-box"
                              onClick={() => {
                                Modal.confirm({
                                  content: '确定要删除该订单吗',
                                  onConfirm: async () => {
                                    setLoading(true);
                                    const res = await removeOrderItem(item.id);
                                    if (res?.code === 200) {
                                      await loadOrders({ order_status: currentTab });
                                      setLoading(false);
                                    }
                                  },
                                });
                              }}
                            >
                              <DeleteOutline className="remove" style={{ fontSize: 16, color: '#666' }} />
                            </Button>
                          )} */}
                          </div>
                        </div>
                        <div
                          className="order-content"
                          // onClick={() => {
                          //   navigate(`/order/detail/${item.sku_id}`);
                          // }}
                        >
                          {item.sku_list?.map((product: Record<string, any>, idx: number) => (
                            <div key={product.sku_id} className={`product-cell ${idx === 0 ? '' : 'border'}`}>
                              {/* <div className="cell-left">
                            <div className="cell-img-box">
                              <Image className="cell-img" src={resize(product.goods_image, 100)} />
                            </div>
                          </div> */}
                              <div className="cell-middle">
                                <span className="cell-title">{product.name}</span>
                                <div className="attr">
                                  <span style={{ fontSize: 12, color: '#666' }}>{product.variant || '默认版'}</span>
                                </div>
                              </div>
                              <div className="cell-right">
                                <div className="cell-price">
                                  <Amount value={product.original_price} size={18} color="#333" />
                                </div>
                                <span className="total">x {product.num}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="cell-footer">
                          {/* {item.status === OrderStatus.WAIT_PAY && (
                              <Button className="btn-cancel" onClick={() => {}}>
                                取消订单
                              </Button>
                            )} */}
                          {/* {item.order_operate_allowable_vo?.allow_receipt && (
                              <Button className="btn" color="primary" fill="outline" onClick={() => {}}>
                                申请发票
                              </Button>
                            )} */}
                          {item.order_operate_allowable_vo?.allow_cancel && (
                            <Button
                              className="btn-cancel"
                              onClick={(event) => {
                                event.stopPropagation();
                                setCurrentOrder(item);
                                setResonVisible(true);
                              }}
                            >
                              取消订单
                            </Button>
                          )}
                          {/* {[OrderStatusEnum.NEW, OrderStatusEnum.CONFIRM].includes(item.order_status) && (
                              <Button className="btn-pay" onClick={() => handlePaynow(item)}>
                                立即支付
                              </Button>
                            )} */}
                          {item.order_operate_allowable_vo?.allow_pay && (
                            <Button
                              className="btn-pay"
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePaynow(item);
                              }}
                            >
                              立即支付
                            </Button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <ErrorBlock status="empty" title={'该分类下暂无订单'} description={null} />
                    </div>
                  )}
                </div>
              )}
            </PullToRefresh>
          </div>
        </div>
        <Picker
          columns={[
            [
              {
                label: '买多了',
                value: '买多了',
              },
              {
                label: '买错商品了',
                value: '买错商品了',
              },
              {
                label: '联系人信息有误',
                value: '联系人信息有误',
              },
              {
                label: '没用优惠',
                value: '没用优惠',
              },
              {
                label: '不想要了',
                value: '不想要了',
              },
              {
                label: '其它',
                value: '其它',
              },
            ],
          ]}
          visible={resonVisible}
          onClose={() => {
            setResonVisible(false);
            setCurrentReson([]);
          }}
          value={currentReson}
          onConfirm={async (val) => {
            setCurrentReson(val);
            if (val.length && val[0] && currentOrder?.sn) {
              await cancelOrderItem({ order_sn: currentOrder.sn, reson: val[0] });
              Toast.show({ content: '订单取消成功' });
              loadOrders({ order_status: currentTab });
            }
          }}
        />
      </div>
    </BasicLayout>
  );
};

export default Container;
